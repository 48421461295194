import React, { useState, useEffect, useMemo } from "react";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import {
  Tab,
  Tabs,
  TextField,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Pagination,
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import DatePicker from "react-datepicker";
import { AiOutlineDownload } from "react-icons/ai";
import { ImPrinter } from "react-icons/im";
import moment from "moment";
import { IoClose } from "react-icons/io5";

import {
  fetchOrderList,
  updateReceiptNumber,
  getOrderDetail,
  updateStatusOrder,
  getDoctorCopyRecipe,
} from "../../../services/OrderApi";
import OrderCard from "./OrderCard";
import CopyRecipeDoctor from "./CopyRecipeDoctor";
import useDebounce from "../../hooks/useDebounce";
import Chatbox from "../../common/particles.jsx/Chatbox";
import OrderStatusModal from "./OrderStatusModal";
import CopyRecipeModal from "./CopyRecipeModal";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";

const toDate = (date) => moment(date).format("DD MMMM YYYY, HH:mm");
const toApiDate = (date) => moment(date).format("YYYY-MM-DD");

const FETCH_LIMIT = 10;

const Order = () => {
  const navigate = useNavigate();

  const [value, setValue] = useState(0);
  const [isDateModalOpen, setIsDateModalOpen] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [resBody, setResBody] = useState({
    limit: FETCH_LIMIT,
    offset: 1,
    start_date: "",
    end_date: "",
    search: "",
    status: [1],
    sort: 2,
  });
  const [prescriptionImg, setPrescriptionImg] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRecipeCopy, setIsRecipeCopy] = useState(false);
  const [isDrugModalOpen, setIsDrugModalOpen] = useState(false);
  const [isRevenueModalOpen, setIsRevenueModalOpen] = useState(false);
  const [updateReceiptBody, setUpdateReceiptBody] = useState({
    invoice_number: "",
    isModalOpen: false,
    receipt_number: "",
  });
  const [detailStatus, setDetailStatus] = useState({
    invoiceNumber: "",
    status: 0,
    shippingId: 0,
    cancelReason: "",
    fullname: "",
    createdAt: "",
    isModalOpen: false,
  });
  const [dataBuffer, setDataBuffer] = useState({});
  const [makeCopyRecipe, setMakeCopyRecipe] = useState(false);
  const [cancelModal, setCancelModal] = useState({
    isModalOpen: false,
    isOtherReason: false,
    cancel_reason: "",
    buffer: "",
  });
  const [recipeCred, setRecipeCred] = useState([]);
  const [pagination, setPagination] = useState(1);
  const [recipeCopyBody, setRecipeCopyBody] = useState([]);
  const [recipeCopy, setRecipeCopy] = useState({
    invoice_number: "",
    isModalOpen: false,
  });
  const [recipeBody, setRecipeBody] = useState({});

  const debounce = useDebounce(resBody.search, 500);

  const {
    mutate: mutateRecipeDoctorCopy,
    data: recipeDoctorCopyData,
    isLoading: isRecipeDoctorCopyLoading,
  } = useMutation({
    mutationFn: (recipeCopy) => getDoctorCopyRecipe(recipeCopy),
  });

  useEffect(() => {
    if (recipeBody) {
      mutateRecipeDoctorCopy(recipeBody);
    }
    if (isDrugModalOpen) {
      mutateRecipeDoctorCopy(recipeBody);
    }
  }, [recipeBody, isDrugModalOpen]);

  const {
    mutate: mutateOrderList,
    data: orderListData,
    isLoading: isOrderListLoading,
  } = useMutation({
    mutationFn: fetchOrderList,
  });

  const pageCount = useMemo(() => {
    if (orderListData === undefined) return 0;
    else if (orderListData.count === undefined) return 0;
    else return Math.ceil(orderListData.count / FETCH_LIMIT);
  }, [orderListData]);

  const {
    mutate: mutateOrderDetail,
    data: orderDetailData,
    isLoading,
  } = useMutation({
    mutationFn: getOrderDetail,
    onSuccess(value) {
      setDataBuffer({
        ...value,
        data: value.data.map((item) => ({ ...item, isAdd: false })),
      });
    },
  });

  const { mutate: mutateUpdateStatusOrder } = useMutation({
    mutationFn: updateStatusOrder,
    onSuccess() {
      setIsDrugModalOpen(false);
      setMakeCopyRecipe(false);
      mutateOrderList(resBody);
    },
  });

  const { mutate: mutateUpdateReceipt } = useMutation({
    mutationFn: updateReceiptNumber,
    onSuccess() {
      setUpdateReceiptBody((prevValue) => ({
        ...prevValue,
        isModalOpen: false,
      }));
    },
  });

  useEffect(() => {
    mutateOrderList(resBody);
  }, [resBody, mutateOrderList, debounce]);

  const [currentIndex, setCurrentIndex] = useState(0);

  const images = prescriptionImg || [];
  const [addCopyRecipe, setAddCopyRecipe] = useState([]);

  useEffect(() => {
    if (images.length > 0) {
      setAddCopyRecipe((prev) =>
        Array(images.length)
          .fill(false)
          .map((_, i) => (prev[i] === true ? true : false))
      );

      setRecipeCopyBody((prev) => {
        if (prev.length === 0) {
          return Array(images.length).fill(null);
        } else {
          return prev;
        }
      });
    }
  }, [images]);

  useEffect(() => {
    if (recipeDoctorCopyData?.length > 0) {
      setAddCopyRecipe((prev) =>
        Array(recipeDoctorCopyData?.length)
          .fill(false)
          .map((_, i) => (prev[i] === true ? true : false))
      );

      setRecipeCopyBody((prev) => {
        if (prev?.length === 0) {
          return Array(recipeDoctorCopyData?.length).fill(null);
        } else {
          return prev;
        }
      });
    }
  }, [images]);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    setPrescriptionImg(
      dataBuffer.data?.map((item) => item?.prescription_image).flat()
    );
  }, [dataBuffer]);

  useEffect(() => {
    setPagination(images.length);
  }, [images]);

  return (
    <div>
      <Chatbox />
      <div className="flex font-bold text-blue-500">
        <p
          className="hover:underline cursor-pointer"
          onClick={() => {
            navigate("/home");
          }}
        >
          Dashboard
        </p>
        <p className="mx-2">/</p>
        <p>Pesanan</p>
      </div>
      <div>
        <Tabs
          sx={{ marginY: 3 }}
          value={value}
          onChange={(_, newValue) => {
            setValue(newValue);
            if (newValue + 1 === 6) {
              setResBody((prevValue) => ({
                ...prevValue,
                status: [6, 7],
              }));
            } else if (newValue + 1 === 3) {
              setResBody((prevValue) => ({
                ...prevValue,
                status: [3, 8],
              }));
            } else {
              setResBody((prevValue) => ({
                ...prevValue,
                status: [newValue + 1],
              }));
            }
          }}
        >
          <Tab label="Semua Pesanan" />
          <Tab label="Pesanan Baru" />
          <Tab label="Siap Dikirim / Siap Diambil" />
          <Tab label="Dalam Pengiriman" />
          <Tab label="Pesanan Selesai" />
          <Tab label="Dibatalkan" />
        </Tabs>
        <div className="flex my-2">
          <TextField
            fullWidth
            placeholder="Cari Produk"
            onChange={(e) => {
              setResBody((prevValue) => ({
                ...prevValue,
                search: e.target.value,
              }));
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <FormControl fullWidth sx={{ marginLeft: 2 }}>
            <InputLabel>Period Penjualan</InputLabel>
            <Select
              label="Tambah Kategori"
              value={resBody.sort}
              onChange={(e) => {
                if (e.target.value === 4) {
                  setIsDateModalOpen(true);
                  setStartDate("");
                  setEndDate("");
                  setResBody((prevValue) => ({
                    ...prevValue,
                    sort: 2,
                  }));
                } else {
                  setResBody((prevValue) => ({
                    ...prevValue,
                    sort: e.target.value,
                  }));
                }
              }}
            >
              <MenuItem value={4}>Pilih Tanggal</MenuItem>
              <MenuItem value={2}>Terbaru</MenuItem>
              <MenuItem value={3}>Terlama</MenuItem>
              <MenuItem value={1} disabled sx={{ display: "none" }}>
                {toApiDate(startDate) + " - " + toApiDate(endDate)}
              </MenuItem>
            </Select>
          </FormControl>
        </div>

        <OrderCard
          isOrderListLoading={isOrderListLoading}
          mutateOrderDetail={mutateOrderDetail}
          isLoading={isLoading}
          orderDetailData={orderDetailData}
          setIsDrugModalOpen={setIsDrugModalOpen}
          orderListData={orderListData}
          setIsModalOpen={setIsModalOpen}
          setRecipeCopy={setRecipeCopy}
          isRecipeCopy={isRecipeCopy}
          setIsRecipeCopy={setIsRecipeCopy}
          setUpdateReceiptBody={setUpdateReceiptBody}
          setDetailStatus={setDetailStatus}
          setIsRevenueModalOpen={setIsRevenueModalOpen}
          setPrescriptionImg={setPrescriptionImg}
          setRecipeBody={setRecipeBody}
        />
      </div>
      <Dialog
        open={isRevenueModalOpen}
        fullWidth
        maxWidth="sm"
        onClose={() => setIsRevenueModalOpen(false)}
      >
        <DialogTitle>Rincian Penghasilan</DialogTitle>
        {orderDetailData && orderDetailData !== undefined ? (
          <DialogContent>
            <div className="w-full border border-black my-2"></div>
            <div className="flex justify-between font-bold">
              <p>Total Penjualan</p>
              <p>
                {orderDetailData.data
                  .reduce((acc, num) => {
                    return acc + num.price * num.quantity;
                  }, 0)
                  .toLocaleString("id-ID", {
                    style: "currency",
                    currency: "IDR",
                  })}
              </p>
            </div>
            <div className="flex justify-between font-bold">
              <p>Ongkos Kirim</p>
              <p>Gratis</p>
            </div>
            <div className="flex justify-between font-bold">
              <p>Biaya Layanan</p>
              <p>
                {orderDetailData.data.reduce((acc, num) => {
                  return acc + num.price * num.quantity;
                }, 0) *
                  (0.2).toLocaleString("id-ID", {
                    style: "currency",
                    currency: "IDR",
                  })}
              </p>
            </div>
            <div className="flex justify-between font-bold">
              <p>Total Diterima</p>
              <p>
                {(
                  orderDetailData.data.reduce((acc, num) => {
                    return acc + num.price * num.quantity;
                  }, 0) -
                  orderDetailData.data.reduce((acc, num) => {
                    return acc + num.price * num.quantity;
                  }, 0) *
                    0.2
                ).toLocaleString("id-ID", {
                  style: "currency",
                  currency: "IDR",
                })}
              </p>
            </div>
          </DialogContent>
        ) : null}
      </Dialog>
      <Dialog
        open={isDrugModalOpen}
        onClose={() => {
          setIsDrugModalOpen(false);
          setMakeCopyRecipe(false);
          setRecipeCopyBody([]);
          setAddCopyRecipe([]);
        }}
        fullWidth
        maxWidth="lg"
        sx={{
          "& .MuiDialog-paper": {
            width: "70%",
            maxWidth: "none",
            height: "100%",
            position: "fixed",
            right: 0,
            margin: 0,
          },
        }}
      >
        <DialogTitle className="pb-1">
          <div className="flex justify-between">
            <p className="font-semibold">
              {makeCopyRecipe ? "Buat Salinan Resep" : "Konfirmasi Resep"}
            </p>
            <IoClose
              className="cursor-pointer"
              onClick={() => {
                setIsDrugModalOpen(false);
                setMakeCopyRecipe(false);
                setRecipeCopyBody([]);
                setAddCopyRecipe([]);
              }}
            />
          </div>
          <p className="text-gray-500 text-sm mt-2 font-extralight">
            Harap pastikan resep yang diunggah customer telah sesuai dengan obat
            yang dipesan
          </p>
          <hr className="my-2" />
        </DialogTitle>
        {orderDetailData && orderDetailData !== undefined && prescriptionImg ? (
          <DialogContent>
            <CopyRecipeDoctor
              dataBuffer={dataBuffer}
              setDataBuffer={setDataBuffer}
              cancelModal={cancelModal}
              setCancelModal={setCancelModal}
              makeCopyRecipe={makeCopyRecipe}
              detailStatus={detailStatus}
              recipeCopyBody={recipeCopyBody}
              setRecipeCopyBody={setRecipeCopyBody}
              addCopyRecipe={addCopyRecipe}
              setAddCopyRecipe={setAddCopyRecipe}
              recipeCred={recipeCred}
              setRecipeCred={setRecipeCred}
              prescriptionImg={images}
              copyRecipeData={recipeDoctorCopyData}
            />
          </DialogContent>
        ) : null}
        <DialogActions className="mx-3 mb-2">
          {makeCopyRecipe ? null : (
            <button
              className="px-3 py-2 rounded-md border-2 border-red-500 text-red-500 font-bold text-[13px] w-full hover:bg-slate-200"
              onClick={() => {
                setCancelModal((prevValue) => ({
                  ...prevValue,
                  isModalOpen: true,
                }));
              }}
            >
              Tolak Pesanan
            </button>
          )}
          {makeCopyRecipe ? null : (
            <button
              className="px-3 py-2 rounded-md border-2 border-blue-500 text-blue-500 font-bold text-[13px] w-full hover:bg-slate-200"
              onClick={() => {
                setMakeCopyRecipe(true);
              }}
            >
              Terima & Buat Salinan Resep
            </button>
          )}
          {!makeCopyRecipe ? (
            <button
              className="px-3 py-2 rounded-md text-white bg-blue-500 font-bold text-[13px] w-full border-2 border-blue-500 hover:bg-blue-700 hover:border-blue-700"
              onClick={() => {
                if (makeCopyRecipe) {
                  mutateUpdateStatusOrder({
                    invoice_number: detailStatus.invoiceNumber,
                    status: detailStatus.shippingId === 4 ? 8 : 3,
                    recipe_copy_data: recipeCopyBody.map((data) => [
                      {
                        doctor_name: data.doctor_name,
                        pro: data.pro,
                        age: data.age,
                        recipe_date: data.recipe_date,
                        notes: data.notes,
                        drug: [
                          {
                            drug_name: data.drug_name,
                            instruction_use: data.instruction_use,
                            quantity: data.quantity,
                            uom: data.uom,
                            det_type: data.det_type,
                            det_iter_qty: data.det_iter_qty,
                            iter_qty: data.iter_qty,
                          },
                        ],
                      },
                    ]),
                  });
                } else {
                  mutateUpdateStatusOrder({
                    invoice_number: detailStatus.invoiceNumber,
                    status: detailStatus.shippingId === 4 ? 8 : 3,
                    recipe_copy_data: [],
                  });
                }
              }}
            >
              Terima Pesanan
            </button>
          ) : (
            <button
              className={`px-3 py-2 rounded-md text-white bg-blue-500 font-bold w-full text-[13px] hover:bg-blue-700 disabled:bg-gray-400`}
              disabled={
                recipeCopyBody[0]?.doctor_name === "" ||
                recipeCopyBody[0]?.pro === "" ||
                recipeCopyBody[0]?.age === 0 ||
                recipeCopyBody[0]?.drug[0]?.drug_name === "" ||
                recipeCopyBody[0]?.drug[0]?.instruction_use === "" ||
                recipeCopyBody[0]?.drug[0].quantity === 0 ||
                recipeCopyBody[0]?.drug[0].det_type === ""
              }
              onClick={() => {
                const filteredRecipeCopyBody = recipeCopyBody.filter(
                  (item) => item !== null
                );

                if (makeCopyRecipe) {
                  mutateUpdateStatusOrder({
                    invoice_number: detailStatus.invoiceNumber,
                    status: detailStatus.shippingId === 4 ? 8 : 3,
                    recipe_copy_data: filteredRecipeCopyBody,
                  });
                } else {
                  mutateUpdateStatusOrder({
                    invoice_number: detailStatus.invoiceNumber,
                    status: detailStatus.shippingId === 4 ? 8 : 3,
                    recipe_copy_data: [],
                  });
                }
              }}
            >
              Buat Salinan Resep
            </button>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
        open={detailStatus.isModalOpen}
        onClose={() =>
          setDetailStatus((prevValue) => ({
            ...prevValue,
            isModalOpen: false,
          }))
        }
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          <p>Status Pesanan</p>
        </DialogTitle>
        <DialogContent sx={{ height: "100%" }}>
          <OrderStatusModal detailStatus={detailStatus} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={updateReceiptBody.isModalOpen}
        fullWidth
        maxWidth="sm"
        onClose={() =>
          setUpdateReceiptBody((prevValue) => ({
            ...prevValue,
            isModalOpen: false,
          }))
        }
      >
        <DialogTitle>Nomor Resi</DialogTitle>
        <DialogContent sx={{ height: "100%" }}>
          <TextField
            label="Nomor Resi"
            fullWidth
            sx={{ marginY: 2 }}
            placeholder="-"
            onChange={(e) => {
              setUpdateReceiptBody((prevValue) => ({
                ...prevValue,
                receipt_number: e.target.value,
              }));
            }}
          />
        </DialogContent>
        <DialogActions>
          <div className="flex">
            <button
              className="text-white p-2 px-3 rounded-md bg-blue-500 font-bold"
              onClick={() => {
                mutateUpdateReceipt({
                  invoice_number: updateReceiptBody.invoice_number,
                  receipt_number: updateReceiptBody.receipt_number,
                });
              }}
            >
              Kirim
            </button>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        open={recipeCopy.isModalOpen}
        onClose={() => {
          setRecipeCopy((prevValue) => ({
            ...prevValue,
            isModalOpen: false,
          }));
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {isRecipeCopy ? "Salinan Resep Dokter" : "Resep Dokter"}
        </DialogTitle>
        <DialogContent sx={{ height: "100%" }}>
          <div className="flex items-center h-full">
            {isRecipeCopy && images.length === 0 ? (
              <CopyRecipeModal recipeDoctorCopyData={recipeDoctorCopyData} />
            ) : (
              <div className="relative flex w-full items-center justify-center">
                {images.length > 0 ? (
                  <>
                    <img
                      src={images[currentIndex]}
                      alt={`Prescription ${currentIndex + 1}`}
                      className="mx-2"
                      style={{ maxWidth: "100%", maxHeight: "500px" }}
                    />
                    {images.length > 1 && (
                      <>
                        <button
                          onClick={handlePrev}
                          className="absolute left-0 top-1/2 transform -translate-y-1/2 p-2 bg-gray-200 bg-opacity-50 rounded-full hover:bg-gray-300 hover:scale-125 hover:duration-200"
                        >
                          <FaCaretLeft />
                        </button>
                        <button
                          onClick={handleNext}
                          className="absolute right-0 top-1/2 transform -translate-y-1/2 p-2 bg-gray-200 bg-opacity-50 rounded-full hover:bg-gray-300 hover:scale-125 hover:duration-200"
                        >
                          <FaCaretRight />
                        </button>
                      </>
                    )}
                  </>
                ) : (
                  <p>No images available</p>
                )}
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <div className="flex justify-between items-end w-full">
            <div className="flex gap-2">
              {images.map((_, index) => (
                <div
                  key={index}
                  className={`flex items-center justify-center w-12 h-12 rounded-lg cursor-pointer
          ${
            currentIndex === index
              ? "bg-blue-900 text-white"
              : "bg-blue-500 text-white hover:bg-blue-600"
          }`}
                  onClick={() => setCurrentIndex(index)}
                >
                  <p className="font-bold">{index + 1}</p>
                </div>
              ))}
            </div>
            <div>
              <button
                className="text-white p-3 rounded-lg bg-blue-500 hover:bg-blue-600"
                onClick={async () => {
                  const response = await fetch(images[currentIndex], {
                    mode: "cors",
                  });
                  const blob = await response.blob();
                  const link = document.createElement("a");
                  const fileName = `Resep-Digital-${currentIndex + 1}.png`;
                  link.href = URL.createObjectURL(blob);
                  link.download = fileName;
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                  URL.revokeObjectURL(link.href);
                }}
              >
                <ImPrinter className="text-[18px]" />
              </button>
              <button
                className="text-white p-3 rounded-lg bg-blue-500 hover:bg-blue-600 ml-2"
                onClick={async () => {
                  const response = await fetch(images[currentIndex], {
                    mode: "cors",
                  });
                  const blob = await response.blob();
                  const link = document.createElement("a");
                  const fileName = `Resep-Digital-${currentIndex + 1}.png`;
                  link.href = URL.createObjectURL(blob);
                  link.download = fileName;
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                  URL.revokeObjectURL(link.href);
                }}
              >
                <AiOutlineDownload className="text-[18px]" />
              </button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isDateModalOpen}
        onClose={() => {
          setIsDateModalOpen(false);
          setStartDate("");
          setEndDate("");
        }}
      >
        <DialogTitle>Pilih Tanggal</DialogTitle>
        <DialogContent>
          <div className="flex">
            <DatePicker
              className="border-2 rounded-md p-2"
              selectsRange
              startDate={startDate}
              endDate={endDate}
              onChange={(dates) => {
                const [start, end] = dates;
                setStartDate(start);
                setEndDate(end);
              }}
              inline
            />
          </div>
          <button
            className="bg-indigo-500 rounded text-white px-2 py-1 font-semibold"
            onClick={() => {
              mutateOrderList({
                limit: FETCH_LIMIT,
                offset: resBody.offset,
                start_date: toApiDate(startDate),
                end_date: toApiDate(endDate),
                search: resBody.search,
                status: resBody.status,
                sort: 1,
              });
              setResBody((prevValue) => ({
                ...prevValue,
                sort: 1,
              }));
              setIsDateModalOpen(false);
            }}
          >
            ok
          </button>
        </DialogContent>
      </Dialog>
      <div className="flex justify-center">
        <Pagination
          count={pageCount}
          page={resBody.offset}
          onChange={(_, value) =>
            setResBody((prevValue) => ({
              ...prevValue,
              offset: value,
            }))
          }
          shape="rounded"
          size="large"
          color="primary"
        />
      </div>
    </div>
  );
};

export default Order;
