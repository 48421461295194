import { useState, useEffect } from "react";
import {
  Button,
  CircularProgress,
  InputAdornment,
  Snackbar,
  IconButton,
  TextField,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { useMutation } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

import {
  getNewPoDetail,
  updatePurchaseOrderNew,
  deleteBillingList,
} from "../../../services/billingAPI";
import { deleteProductPO } from "../../../services/purchaseOrderApi";
import { BsTrashFill } from "react-icons/bs";
import { DesktopDatePicker } from "@mui/x-date-pickers";

const toDate = (date) => moment(date).format("YYYY-MM-DD");

const CreateBillingManual = ({ isWarehouse }) => {
  const navigate = useNavigate();
  const { id, flag } = useParams();
  const [reqBody, setReqBody] = useState({
    warehouse: isWarehouse ? isWarehouse : false,
    po_id: 0,
    po_number: "",
    invoice: "",
    due_date: null,
    distribution: {
      name: "",
      address: "",
      phone: "",
    },
    total_price: 0,
    is_draft: true,
    is_partial: false,
    is_invoice: false,
    product: [],
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [isSaveBillingModalOpen, setIsSaveBillingModalOpen] = useState(false);

  const { mutate: mutateFetchPoDetail } = useMutation({
    mutationFn: getNewPoDetail,
    onSuccess: (data) => {
      setReqBody({
        warehouse: isWarehouse ? isWarehouse : false,
        po_id: Number(id),
        po_number: data.po_number,
        invoice: data.invoice_number,
        due_date: toDate(data.due_date),
        distribution: {
          name: data.distribution?.name,
          phone: data.distribution?.phone,
          address: data.distribution?.address,
        },
        total_price: 0,
        is_draft: data.is_draft,
        is_partial: data.is_partial,
        product: data.product
          .filter((data) => data.po_stock - data.filled_po_stock > 0)
          .map((data) => ({
            id: data.id,
            product_detail_id: data.product_detail_id,
            name: data.name,
            type_stock: data.type_stock,
            packaging: data.packaging,
            filled_po_stock: data.filled_po_stock,
            po_stock: data.po_stock,
            actual_stock: data.po_stock - data.filled_po_stock,
            po_pharmacy_net_price: data.po_pharmacy_net_price,
            po_pharmacy_net_price_comparer: data.po_pharmacy_net_price_comparer,
            pharmacy_net_price_discount: data.pharmacy_net_price_discount,
            pharmacy_net_price_ppn: data.pharmacy_net_price_ppn,
            pharmacy_net_price_real: data.pharmacy_net_price_real,
            batch:
              data.po_batch !== null
                ? data.po_batch.map((item) => ({
                    product_detail_id: data.product_detail_id,
                    number: item.batch_number,
                    stock: item.stock,
                    expired_date: item.expired_date,
                  }))
                : [],
          })),
      });
    },
  });

  const { mutate: mutateUpdatePoNew, isLoading: isMutateUpdatePoNewLoading } =
    useMutation({
      mutationFn: updatePurchaseOrderNew,
      onSuccess: () => {
        navigate(
          `${isWarehouse ? "/central-warehouse" : ""}/manual-billing/unpaid`
        );
      },
      onError: (err) => {
        setErrorMessage(err.message.id);
      },
    });

  const { mutate: mutateDeleteBilling } = useMutation({
    mutationFn: deleteProductPO,
  });

  const typeStockChecker = (item) => {
    if (item.type_stock === 0) {
      return item.packaging?.type_box;
    } else if (item.type_stock === 1) {
      return item.packaging?.type_strip;
    } else {
      return item.packaging?.type_unit;
    }
  };

  const pharmacyNetPriceFinal = (
    discountPercent,
    pharmacyNetPriceReal,
    isTaxEnabled
  ) => {
    let discount = pharmacyNetPriceReal * (discountPercent / 100);
    let value = (pharmacyNetPriceReal - discount) * 0.11;
    if (!isTaxEnabled) {
      value = 0;
    }
    const res = pharmacyNetPriceReal - discount + value;
    return res;
  };

  const validateDetailUpdateStock = (data) => {
    let isValid = true;

    data?.product?.forEach((item) => {
      // if (item.batch.length === 0) {
      //   isValid = false;
      // }
      if (
        item.actual_stock > 0 &&
        item.batch?.some(
          (it) => it.number === "" || it.stock === 0 || it.expired_date === null
        )
      ) {
        setErrorMessage(
          "nama, kuantitas, atau expired date batch tidak boleh kosong"
        );
        isValid = false;
      }
      if (
        item.actual_stock <= 0 &&
        item.batch?.some(
          (it) => it.number !== "" || it.stock !== 0 || it.expired_date !== null
        )
      ) {
        setErrorMessage("total kuantitas batch harus sesuai dengan stock");
        isValid = false;
      }
      if (
        item.batch?.some((it) => it.stock !== 0) &&
        item.batch?.filter((it) => it.stock !== 0).length > 0 &&
        item.batch
          .filter((it) => it.stock !== 0)
          .reduce((acc, curr) => {
            return acc + curr.stock;
          }, 0) > item.actual_stock
      ) {
        setErrorMessage("total kuantitas batch harus sesuai dengan stock");
        isValid = false;
      }
    });
    if (
      data?.product?.reduce((acc, curr) => {
        return acc + curr.batch.length;
      }, 0) === 0
    ) {
      isValid = false;
      setErrorMessage("Minimal 1 batch harus diisi");
    }
    if (data?.invoice?.length === 0) {
      isValid = false;
      setErrorMessage("Nomor invoice tidak boleh kosong");
    }
    return isValid;
  };

  useEffect(() => {
    mutateFetchPoDetail({
      po_id: Number(id),
      is_partial: flag === "true" ? true : false,
      is_billing: true,
      is_draft: false,
      warehouse: isWarehouse ? isWarehouse : false,
    });
  }, [mutateFetchPoDetail, id, flag]);

  return (
    <div>
      <div className="shadow-md rounded-md p-4">
        <p className="text-blue-500 font-bold pt-2">Create Billing Draft</p>
        <hr className="my-2" />
        {reqBody && reqBody !== undefined ? (
          <>
            <div className="lg:flex gap-2">
              <div className="w-full flex items-center">
                <div className="w-full font-bold">Input PO Date</div>
                <div className="w-full">
                  <TextField
                    fullWidth
                    disabled
                    value={moment(reqBody.po_date).format("DD MMMM YYYY")}
                  />
                </div>
              </div>
              <div className="w-full flex items-center my-2">
                <div className="w-full font-bold">PO Number</div>
                <div className="w-full">
                  <TextField
                    disabled
                    fullWidth
                    sx={{ backgroundColor: "#d8d8d8" }}
                    value={reqBody.po_number}
                  />
                </div>
              </div>
            </div>
            <div className="lg:flex gap-2 mt-2">
              <div className="w-full flex items-center">
                <div className="w-full font-bold">
                  Nama Distributor / Principal
                </div>
                <div className="w-full">
                  <TextField
                    fullWidth
                    value={reqBody.distribution?.name}
                    disabled
                  />
                </div>
              </div>
              <div className="w-full flex items-center my-2">
                <div className="w-full font-bold">
                  No. Telp. Distributor / Principal
                </div>
                <div className="w-full">
                  <TextField
                    fullWidth
                    value={reqBody.distribution?.phone}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="w-full font-bold mt-4 mb-2">
              Alamat Distributor / Principal
            </div>
            <div className="w-full">
              <TextField
                fullWidth
                multiline
                rows={4}
                value={reqBody.distribution?.address}
                disabled
              />
            </div>
            <div className="lg:flex gap-2 mt-2">
              <div className="w-full flex items-center">
                <div className="w-full font-bold">Invoice Number</div>
                <div className="w-full">
                  <TextField
                    fullWidth
                    value={reqBody.invoice}
                    onChange={(e) =>
                      setReqBody((prevValue) => ({
                        ...prevValue,
                        invoice: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
              <div className="w-full flex items-center my-2">
                <div className="w-full font-bold">Bayar Sebelum</div>
                <div className="w-full">
                  <DesktopDatePicker
                    fullWidth
                    className="w-full"
                    value={reqBody.due_date}
                    onChange={(newExpiredDate) => {
                      setReqBody((prevValue) => ({
                        ...prevValue,
                        due_date: toDate(newExpiredDate),
                      }));
                    }}
                    renderInput={(params) => {
                      return <TextField {...params} />;
                    }}
                  />
                </div>
              </div>
            </div>

            <hr className="my-4" />
            <p className="font-bold">Produk List</p>

            {reqBody.product !== null &&
            reqBody.product?.length > 0 &&
            reqBody.product ? (
              reqBody.product?.map((item, i) => (
                <div key={i}>
                  <div className="shadow-md rounded-md p-4 mt-4">
                    <table className="w-full">
                      <tr className="font-bold">
                        <td className="w-[50%]">Name Produk</td>
                        <td className="w-[20%]">Kemasan</td>
                        <td className="w-[20%]">Total Harga</td>
                        <td
                          rowSpan={2}
                          className="flex items-center justify-end"
                        >
                          <button
                            className="bg-red-500 hover:bg-red-700 text-white p-2 rounded-md cursor-pointer w-fit disabled:bg-gray-300"
                            disabled={reqBody.product.length === 1}
                            onClick={() => {
                              mutateDeleteBilling(
                                item.id,
                                isWarehouse ? isWarehouse : false
                              );
                              setReqBody((prevValue) => ({
                                ...prevValue,
                                product: prevValue.product.filter(
                                  (data) => data.id !== item.id
                                ),
                              }));
                            }}
                          >
                            <BsTrashFill />
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td className="w-[50%]">{item.name}</td>
                        <td className="w-[20%]">{typeStockChecker(item)}</td>
                        <td className="w-[20%]">
                          {pharmacyNetPriceFinal(
                            item.pharmacy_net_price_discount,
                            item.pharmacy_net_price_real,
                            item.pharmacy_net_price_ppn
                          )
                            .toLocaleString("id-ID", {
                              style: "currency",
                              currency: "IDR",
                            })
                            .replace(",", ".")}
                        </td>
                      </tr>
                    </table>
                    {pharmacyNetPriceFinal(
                      item.pharmacy_net_price_discount,
                      item.pharmacy_net_price_real,
                      item.pharmacy_net_price_ppn
                    ) > item.po_pharmacy_net_price_comparer ? (
                      <p className="text-red-500 text-end mt-3">
                        Anda menaikan harga akhir, ada potensi kerugian
                      </p>
                    ) : null}
                    <div className="flex gap-2 my-4">
                      <div className="flex justify-center w-full">
                        <button
                          type="button"
                          className="rounded-l-md py-2 px-3 bg-[#FF7A5D] disabled:bg-gray-200 flex items-center"
                          disabled={item.actual_stock === 0}
                          onClick={() => {
                            setReqBody((prevValue) => ({
                              ...prevValue,
                              product: prevValue.product.map((data) => {
                                return data.id === item.id
                                  ? {
                                      ...item,
                                      actual_stock: item.actual_stock - 1,
                                    }
                                  : data;
                              }),
                            }));
                          }}
                        >
                          -
                        </button>
                        <input
                          type="text"
                          className="bg-slate-100 p-2 w-full"
                          value={item.actual_stock}
                          onChange={(e) => {
                            let numericValue = e.target.value;
                            if (numericValue === "") {
                              numericValue = "0";
                            }
                            setReqBody((prevValue) => ({
                              ...prevValue,
                              product: prevValue.product.map((data) => {
                                return data.id === item.id
                                  ? {
                                      ...item,
                                      actual_stock: parseInt(
                                        numericValue.replace(/[^0-9]/g, 0),
                                        10
                                      ),
                                    }
                                  : data;
                              }),
                            }));
                          }}
                        />
                        <button
                          type="button"
                          className="rounded-r-md bg-[#5DFFBB] py-2 px-3 disabled:bg-gray-200 flex items-center"
                          onClick={() => {
                            setReqBody((prevValue) => ({
                              ...prevValue,
                              product: prevValue.product.map((data) => {
                                return data.id === item.id
                                  ? {
                                      ...item,
                                      actual_stock: item.actual_stock + 1,
                                    }
                                  : data;
                              }),
                            }));
                          }}
                        >
                          +
                        </button>
                      </div>
                      <TextField
                        label="HNA"
                        value={item.pharmacy_net_price_real}
                        onChange={(e) => {
                          let numericValue = e.target.value;
                          if (numericValue === "") {
                            numericValue = "0";
                          }
                          setReqBody((prevValue) => ({
                            ...prevValue,
                            product: prevValue.product.map((data) => {
                              return data.id === item.id
                                ? {
                                    ...item,
                                    pharmacy_net_price_real: parseInt(
                                      numericValue.replace(/[^0-9]/g, 0),
                                      10
                                    ),
                                  }
                                : data;
                            }),
                          }));
                        }}
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              Rp.
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <TextField
                      label="Discount"
                      fullWidth
                      value={
                        typeof item.pharmacy_net_price_discount === "number"
                          ? item.pharmacy_net_price_discount
                              .toString()
                              .replace(".", ",")
                          : item.pharmacy_net_price_discount
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                      }}
                      onChange={(e) => {
                        let numericValue = e.target.value.replace(",", ".");
                        if (
                          Number(numericValue) >= 0 &&
                          Number(numericValue) <= 100
                        )
                          setReqBody((prevValue) => ({
                            ...prevValue,
                            product: prevValue.product.map((data) => {
                              return data.id === item.id
                                ? {
                                    ...item,
                                    pharmacy_net_price_discount:
                                      numericValue.slice(-1) === "."
                                        ? numericValue.replace(".", ",")
                                        : Number(numericValue),
                                  }
                                : data;
                            }),
                          }));
                      }}
                    />
                    <div className="flex items-center mt-4">
                      <p className="font-bold">PPN 11%</p>
                      <Switch
                        checked={item.pharmacy_net_price_ppn}
                        onClick={() => {
                          setReqBody((prevValue) => ({
                            ...prevValue,
                            product: prevValue.product.map((data) => {
                              return data.id === item.id
                                ? {
                                    ...item,
                                    pharmacy_net_price_ppn:
                                      !item.pharmacy_net_price_ppn,
                                  }
                                : data;
                            }),
                          }));
                        }}
                      />
                    </div>
                    <hr className="my-4" />
                    {item.batch
                      .filter((it) => it.stock !== 0)
                      .reduce((acc, curr) => {
                        return acc + curr.stock;
                      }, 0) > item.actual_stock ? (
                      <div className="flex">
                        <p className="text-red-500">
                          Stock melebihi batas maksimal
                        </p>
                      </div>
                    ) : null}
                    <div>
                      {item.batch.map((prod, prodIndex) => {
                        return (
                          <div
                            className="flex w-full gap-2 my-2"
                            key={prodIndex}
                          >
                            <TextField
                              label="Nomor Batch"
                              fullWidth
                              value={prod.number}
                              onChange={(e) => {
                                setReqBody((prevValue) => ({
                                  ...prevValue,
                                  product: prevValue.product.map((data) => ({
                                    ...data,
                                    batch: data.batch.map((value, index) => {
                                      return index === prodIndex &&
                                        data.id === item.id
                                        ? {
                                            ...prod,
                                            number: e.target.value,
                                          }
                                        : value;
                                    }),
                                  })),
                                }));
                              }}
                            />
                            <TextField
                              label="Kuantitas"
                              fullWidth
                              value={prod.stock === 0 ? "" : prod.stock}
                              onChange={(e) => {
                                let numericValue = e.target.value;
                                if (numericValue === "") {
                                  numericValue = "0";
                                }
                                setReqBody((prevValue) => ({
                                  ...prevValue,
                                  product: prevValue.product.map((data) => ({
                                    ...data,
                                    batch: data.batch.map((value, index) => {
                                      return index === prodIndex &&
                                        data.id === item.id
                                        ? {
                                            ...prod,
                                            stock: parseInt(
                                              numericValue.replace(
                                                /[^0-9]/g,
                                                ""
                                              ),
                                              10
                                            ),
                                          }
                                        : value;
                                    }),
                                  })),
                                }));
                              }}
                            />

                            <div className="w-full">
                              <DesktopDatePicker
                                className="w-full"
                                fullWidth
                                value={prod.expired_date}
                                onChange={(newExpiredDate) => {
                                  setReqBody((prevValue) => ({
                                    ...prevValue,
                                    product: prevValue.product.map((data) => ({
                                      ...data,
                                      batch: data.batch.map((value, index) => {
                                        return index === prodIndex &&
                                          data.id === item.id
                                          ? {
                                              ...prod,
                                              expired_date:
                                                toDate(newExpiredDate),
                                            }
                                          : value;
                                      }),
                                    })),
                                  }));
                                }}
                                renderInput={(params) => {
                                  return <TextField {...params} />;
                                }}
                                disablePast
                              />
                            </div>
                            <div className="w-[56px] flex items-center justify-center">
                              <BsTrashFill
                                className={`${
                                  prodIndex === 0
                                    ? "text-gray-200"
                                    : "text-red-500 hover:text-red-900 cursor-pointer"
                                } w-full h-full`}
                                onClick={() => {
                                  setReqBody((prevValue) => ({
                                    ...prevValue,
                                    product: prevValue.product.filter(
                                      (data) => ({
                                        ...data,
                                        batch: data.batch.splice(prodIndex, 1),
                                      })
                                    ),
                                  }));
                                }}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <Button
                      disabled={
                        item.batch.length === item.actual_stock ||
                        item.batch.reduce((acc, num) => {
                          return acc + num.stock;
                        }, 0) >= item.actual_stock
                      }
                      onClick={() => {
                        setReqBody((prevValue) => ({
                          ...prevValue,
                          product: prevValue.product.map((data) => {
                            return data.id === item.id
                              ? {
                                  ...data,
                                  batch: [
                                    ...data.batch,
                                    {
                                      product_detail_id: item.product_detail_id,
                                      number: "",
                                      stock: 0,
                                      expired_date: null,
                                    },
                                  ],
                                }
                              : data;
                          }),
                        }));
                      }}
                    >
                      + Tambah Batch Lain
                    </Button>
                  </div>
                </div>
              ))
            ) : (
              <div></div>
            )}
          </>
        ) : (
          <CircularProgress size={20} />
        )}
        <div className="flex justify-end font-bold mt-3">
          <p>Total Harga: </p>
          <p className="text-blue-500 ml-2">
            {reqBody.product
              .reduce((acc, curr) => {
                let discount =
                  curr.pharmacy_net_price_real *
                  (curr.pharmacy_net_price_discount / 100);
                let value = (curr.pharmacy_net_price_real - discount) * 0.11;
                if (!curr.pharmacy_net_price_ppn) {
                  value = 0;
                }
                return (
                  acc +
                  (curr.pharmacy_net_price_real - discount + value) *
                    curr.actual_stock
                );
              }, 0)
              .toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
              })}
          </p>
        </div>

        <div className="w-full flex gap-2 mt-8">
          <button
            className="border border-black py-2 rounded-md w-full"
            onClick={() =>
              navigate(
                `${
                  isWarehouse ? "/central-warehouse" : ""
                }/manual-billing/unpaid`
              )
            }
          >
            Cancel
          </button>
          <button
            disabled={
              !reqBody.product.some(
                (data) => data.po_stock - data.filled_po_stock > 0
              ) ||
              !reqBody.product.every(
                (item) => typeof item.pharmacy_net_price_discount === "number"
              )
            }
            className="bg-blue-500 py-2 rounded-md text-white w-full font-bold disabled:bg-gray-300 "
            onClick={() => {
              if (validateDetailUpdateStock(reqBody)) {
                setIsSaveBillingModalOpen(true);
              } else {
                return;
              }
            }}
          >
            Save
          </button>
        </div>
      </div>
      <Dialog
        open={isSaveBillingModalOpen}
        onClose={() => setIsSaveBillingModalOpen(false)}
      >
        <DialogTitle>
          <div className="flex justify-between items-center">
            <p>Save Billing</p>
            <p
              className="text-[35px] cursor-pointer"
              onClick={() => setIsSaveBillingModalOpen(false)}
            >
              X
            </p>
          </div>
        </DialogTitle>
        <DialogContent>
          Simpan sebagai manual billing atau sebagai draft untuk mengubah
          nantinya
          {isMutateUpdatePoNewLoading ? (
            <div className="flex">
              <CircularProgress />
            </div>
          ) : (
            <div>
              <div className="flex mt-3 gap-2">
                <button
                  className="border border-black py-2 rounded-md w-full"
                  onClick={() => {
                    mutateUpdatePoNew({
                      ...reqBody,
                      is_partial: reqBody.product.some(
                        (data) =>
                          data.actual_stock >
                          data.batch.reduce((acc, curr) => {
                            return acc + curr.stock;
                          }, 0)
                      ),

                      is_draft: true,
                      product: reqBody.product
                        .filter((data) => data.batch.length !== 0)
                        .map((data) => ({
                          id: data.id,
                          actual_stock: data.actual_stock,
                          type_stock: data.type_stock,
                          pharmacy_net_price_real: data.pharmacy_net_price_real,
                          pharmacy_net_price_discount:
                            data.pharmacy_net_price_discount,
                          pharmacy_net_price_ppn: data.pharmacy_net_price_ppn,
                          batch: data.batch.map((item) => ({
                            product_detail_id: item.product_detail_id,
                            number: item.number,
                            stock: item.stock,
                            expired_date: item.expired_date,
                          })),
                        })),
                    });
                  }}
                >
                  Simpan as draft
                </button>
                <button
                  className="bg-blue-500 py-2 rounded-md text-white w-full font-bold"
                  onClick={() => {
                    mutateUpdatePoNew({
                      ...reqBody,
                      is_partial: reqBody.product.some(
                        (data) =>
                          data.actual_stock >
                          data.batch.reduce((acc, curr) => {
                            return acc + curr.stock;
                          }, 0)
                      ),
                      product: reqBody.product
                        .filter((data) => data.batch.length !== 0)
                        .map((data) => ({
                          id: data.id,
                          actual_stock:
                            data.actual_stock + data.filled_po_stock,
                          type_stock: data.type_stock,
                          pharmacy_net_price_real: data.pharmacy_net_price_real,
                          pharmacy_net_price_discount:
                            data.pharmacy_net_price_discount,
                          pharmacy_net_price_ppn: data.pharmacy_net_price_ppn,
                          batch: data.batch.map((item) => ({
                            product_detail_id: item.product_detail_id,
                            number: item.number,
                            stock: item.stock,
                            expired_date: item.expired_date,
                          })),
                        })),
                    });
                  }}
                >
                  Simpan
                </button>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
      <Snackbar
        open={Boolean(errorMessage)}
        onClose={() => setErrorMessage("")}
        message={errorMessage}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setErrorMessage("")}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </div>
  );
};

export default CreateBillingManual;
