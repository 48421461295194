import { useEffect, useState } from "react";
import BreadcrumbPatientCare from "../BreadcrumbPatientCare";
import useDebounce from "../../../hooks/useDebounce";
import { MdOutlineFilterAlt } from "react-icons/md";
import moment from "moment";
import { IoCalendarOutline } from "react-icons/io5";
import {
  CircularProgress,
  InputAdornment,
  Popover,
  TextField,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { FaChevronDown, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import {
  deleteRecipe,
  getPrescriptionHistoryList,
  getRmeList,
  uploadImage,
  uploadRecipe,
} from "../../../../services/patientCare";
import { useMutation } from "@tanstack/react-query";
import PagePagination from "../../../common/particles.jsx/PagePagination";
import PopupRangeCalendar from "../../../common/particles.jsx/PopupRangeCalendar";
import { RxCross1 } from "react-icons/rx";
import PopoverDoctorList from "../PopoverDoctorList";
import PopoverPatientList from "../PopoverPatientList";
import { LuClipboardList } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import LoadingBackdrop from "../../../common/particles.jsx/LoadingBackdrop";

const INITIAL_REQ = {
  offset: 1,
  limit: 10,
  search: "",
  doctor_id: 0,
  patient_id: 0,
  start_date: "",
  end_date: "",
};

const PrescriptionList = () => {
  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [reqBody, setReqBody] = useState(INITIAL_REQ);
  const [anchorElCal, setAnchorElCal] = useState(null);
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [anchorElDoctor, setAnchorElDoctor] = useState(null);
  const [anchorElPatient, setAnchorElPatient] = useState(null);
  const [doctorArr, setDoctorArr] = useState([]);
  const [searchDoctor, setSearchDoctor] = useState("");
  const [patientArr, setPatientArr] = useState([]);
  const [searchPatient, setSearchPatient] = useState("");
  const [selectedRecipe, setSelectedRecipe] = useState(null);

  const debounce = useDebounce(search, 500);
  const debounceDoctor = useDebounce(searchDoctor, 500);
  const debouncePatient = useDebounce(searchPatient, 500);

  const resetReq = () => {
    setReqBody({ ...INITIAL_REQ });
    setDoctorArr([]);
    setPatientArr([]);
  };

  const refetchList = (searchStr) => {
    mutateList({
      ...reqBody,
      doctor_id: doctorArr.length === 0 ? 0 : doctorArr[0].id,
      patient_id: patientArr.length === 0 ? 0 : patientArr[0].id,
      search: searchStr,
    });
  };

  const { mutate: mutateUploadImage, isLoading: isLoadingImage } = useMutation({
    mutationFn: uploadImage,
    onSuccess: (data) => {
      if (data && selectedRecipe)
        mutateUploadRecipe({
          id: Number(selectedRecipe.id),
          attachment: data,
        });
      setSelectedRecipe(null);
    },
    onError: () => {
      setSelectedRecipe(null);
    },
  });

  const { mutate: mutateUploadRecipe, isLoading: isLoadingUploadRecipe } =
    useMutation({
      mutationFn: uploadRecipe,
      onSuccess: () => {
        setSearch("");
        refetchList("");
      },
    });

  const { mutate: mutateDeleteRecipe, isLoading: isLoadingDeleteRecipe } =
    useMutation({
      mutationFn: deleteRecipe,
      onSuccess: () => {
        setSearch("");
        refetchList("");
      },
    });

  const {
    mutate: mutateList,
    data: dataList,
    isLoading,
  } = useMutation({
    mutationFn: getPrescriptionHistoryList,
  });

  useEffect(() => {
    refetchList(debounce);
  }, [debounce, reqBody, doctorArr, patientArr]);

  useEffect(() => {
    setReqBody((prevValue) => ({ ...prevValue, offset: 1, limit: 10 }));
  }, [debounce, doctorArr, patientArr, reqBody.end_date]);

  useEffect(() => {
    if (selectedRecipe) mutateUploadImage(selectedRecipe.file);
  }, [selectedRecipe]);
  return (
    <>
      <LoadingBackdrop
        isLoading={
          isLoadingImage || isLoadingUploadRecipe || isLoadingDeleteRecipe
        }
      />
      <BreadcrumbPatientCare />
      <div className="flex items-center gap-2 mb-2">
        <TextField
          fullWidth
          placeholder="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          inputProps={{ style: { padding: 8 } }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <div
          className="w-full basis-1/3 justify-between p-2 rounded-md border-2 flex gap-2 items-center text-gray-500 hover:bg-gray-100 cursor-pointer text-sm"
          onClick={(e) => setAnchorElCal(e.currentTarget)}
        >
          <IoCalendarOutline />
          <p className="whitespace-nowrap">
            {(reqBody.start_date === "") & (reqBody.end_date === "")
              ? "No Date Choosen"
              : moment(reqBody.start_date).format("DD MMM YYYY") +
                "-" +
                moment(reqBody.end_date).format("DD MMM YYYY")}
          </p>
          <FaChevronDown />
        </div>
        <div
          className="rounded-md p-2 border-2 flex justify-center items-center cursor-pointer hover:bg-gray-300"
          onClick={(e) => setAnchorElFilter(e.currentTarget)}
        >
          <MdOutlineFilterAlt className="w-5 h-5" />
        </div>
      </div>
      <p className="text-gray-500 text-xs mb-4">
        You can search by prescription number or patient name
      </p>
      <div className="flex w-full mb-8">
        <div className="w-full">
          <div className="block overflow-x-auto w-full rounded-md border">
            <table className="w-full rounded-md">
              <thead className="bg-blue-500 text-white">
                <th className="p-2 text-center">No</th>
                <th className="p-2 text-center">No. Resep</th>
                <th className="p-2 text-center">Nama Pasien</th>
                <th className="p-2 text-center">Nama Dokter</th>
                <th className="p-2 text-center">Tanggal Resep</th>
                <th className="p-2 text-center">Tanggal Tebus Resep</th>
                <th className="p-2 text-center">Iter</th>
                <th className="p-2 text-center">Sisa Iter</th>
                <th className="p-2 text-center">Attachment</th>
                <th className="p-2 text-center">Action</th>
              </thead>
              <tbody>
                {dataList &&
                !isLoading &&
                dataList.count > 0 &&
                dataList.data ? (
                  dataList.data.map((item, i) => {
                    return (
                      <tr
                        className="border-collapse border border-gray-500"
                        key={i}
                      >
                        <td className="p-2 text-center border border-gray-500">
                          {i + 1}
                        </td>
                        <td
                          className="p-2 text-center border border-gray-500 text-blue-500 hover:text-gray-300 cursor-pointer"
                          onClick={() =>
                            navigate(
                              `/patient-care/prescription-history/detail/${item.id}`
                            )
                          }
                        >
                          {item.prescription_no === ""
                            ? "-"
                            : item.prescription_no}
                        </td>
                        <td className="p-2 text-center border border-gray-500">
                          {item.patient_name === "" ? "-" : item.patient_name}
                        </td>
                        <td className="p-2 text-center border border-gray-500">
                          {item.doctor_name === "" ? "-" : item.doctor_name}
                        </td>
                        <td className="p-2 text-center border border-gray-500">
                          {moment(item.prescription_date).format(
                            "DD MMMM YYYY"
                          )}
                        </td>
                        <td className="p-2 text-center border border-gray-500 ">
                          {moment(item.redeem_date).format("DD MMMM YYYY")}
                        </td>
                        <td className="p-2 text-center border border-gray-500">
                          {item.iter ? "Yes" : "No"}
                        </td>
                        <td className="p-2 text-center border border-gray-500">
                          {item.iter_left}
                        </td>
                        <td className="p-2 text-center border border-gray-500">
                          {item.prescription_no[0] !== "I" &&
                          item.attachment === "" ? (
                            <>
                              <input
                                type="file"
                                id="doctor-img-upload-exam"
                                hidden
                                accept="accept='image/jpeg,image/png,image/jpg"
                                onChange={async (e) => {
                                  setSelectedRecipe({
                                    ...item,
                                    file: e.target.files[0],
                                  });
                                }}
                              />
                              <label for="doctor-img-upload-exam">
                                <div
                                  className={`border-2 border-blue-500 text-blue-500 rounded-md hover:bg-gray-100 cursor-pointer`}
                                >
                                  {(isLoadingUploadRecipe || isLoadingImage) &&
                                  selectedRecipe &&
                                  selectedRecipe.id === item.id ? (
                                    <CircularProgress size={20} />
                                  ) : (
                                    <p>Upload Prescription</p>
                                  )}
                                </div>
                              </label>
                            </>
                          ) : item.prescription_no[0] === "I" ? (
                            <div className="text-blue-500 text-center">
                              {item.prescription_no}.jpg
                            </div>
                          ) : (
                            <div className="text-blue-500 text-center">
                              {" "}
                              <div className="flex gap-2 items-center justify-center">
                                <p className="text-blue-500">
                                  {item.filename !== ""
                                    ? item.filename
                                    : item.prescription_no + ".jpg"}
                                </p>
                                <RxCross1
                                  className="text-red-500 hover:text-red-700 cursor-pointer"
                                  onClick={() => mutateDeleteRecipe(item.id)}
                                />
                              </div>
                            </div>
                          )}
                        </td>
                        <td
                          className={`p-2 text-center border border-gray-500  ${
                            item.prescription_no[0] !== "I" &&
                            item.attachment === ""
                              ? "text-gray-300 cursor-default"
                              : "text-blue-500 hover:text-gray-300 cursor-pointer"
                          }`}
                          onClick={() => {
                            if (
                              !(
                                item.prescription_no[0] !== "I" &&
                                item.attachment === ""
                              )
                            )
                              navigate(
                                `/patient-care/prescription-history/make-copy/${item.id}`
                              );
                          }}
                        >
                          <div className="flex gap-2 items-center">
                            <p>Copy</p>
                            <LuClipboardList />
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : isLoading ? (
                  <tr>
                    <td
                      colSpan={10}
                      className="text-center p-2 border border-gray-500"
                    >
                      <CircularProgress size={20} />
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td
                      colSpan={10}
                      className="text-center text-gray-300 p-2 border border-gray-500"
                    >
                      Data prescription not found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {dataList && !isLoading && dataList.count > 0 ? (
        <PagePagination
          offset={reqBody.offset}
          setOffset={(e) => {
            setReqBody((prevValue) => ({ ...prevValue, offset: e }));
          }}
          limit={reqBody.limit}
          setLimit={(e) => {
            setReqBody((prevValue) => ({ ...prevValue, limit: e }));
          }}
          total={dataList.count}
        />
      ) : null}
      <PopupRangeCalendar
        anchorElCal={anchorElCal}
        setAnchorElCal={setAnchorElCal}
        startDate={reqBody.start_date}
        endDate={reqBody.end_date}
        setStartDate={(e) => {
          setReqBody((prevValue) => ({ ...prevValue, start_date: e }));
        }}
        setEndDate={(e) => {
          setReqBody((prevValue) => ({ ...prevValue, end_date: e }));
        }}
      />
      <Popover
        open={Boolean(anchorElFilter)}
        anchorEl={anchorElFilter}
        onClose={() => setAnchorElFilter(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="px-8 py-2 w-full min-w-[20vw]">
          <div className=" grid grid-cols-3 items-center gap-4">
            <FaChevronLeft
              className="hover:text-red-500"
              onClick={() => setAnchorElFilter(null)}
            />
            <p className="font-bold text-center">Filter</p>
            <p
              className="text-red-500 cursor-pointer hover:text-gray-500 text-right"
              onClick={resetReq}
            >
              Reset
            </p>
          </div>
        </div>
        <hr />
        <div>
          <div
            className="px-8 py-2 hover:bg-gray-300 cursor-pointer border-b-2 flex justify-between"
            onClick={(e) => setAnchorElDoctor(e.currentTarget)}
          >
            <p>Doctor</p>
            <FaChevronRight />
          </div>
          <div className="p-2 grid grid-cols-2 gap-2 border-b-2">
            {doctorArr.length > 0 ? (
              doctorArr.map((item) => (
                <div className="flex items-center justify-between gap-2 bg-gray-200 p-1 rounded-md w-full">
                  <p>{item.name}</p>
                  <RxCross1
                    className="text-red-500 cursor-pointer hover:text-red-700"
                    onClick={() => {
                      setDoctorArr([]);
                    }}
                  />
                </div>
              ))
            ) : (
              <p className="text-gray-300 w-full ml-8">No doctor selected</p>
            )}
          </div>
          <div
            className="px-8 py-2 hover:bg-gray-300 cursor-pointer border-b-2 flex justify-between"
            onClick={(e) => setAnchorElPatient(e.currentTarget)}
          >
            <p>Patient</p>
            <FaChevronRight />
          </div>
          <div className="p-2 grid grid-cols-2 gap-2">
            {patientArr.length > 0 ? (
              patientArr.map((item) => (
                <div className="flex items-center justify-between gap-2 bg-gray-200 p-1 rounded-md w-full">
                  <p>{item.patient_name}</p>
                  <RxCross1
                    className="text-red-500 cursor-pointer hover:text-red-700"
                    onClick={() => {
                      setPatientArr([]);
                    }}
                  />
                </div>
              ))
            ) : (
              <p className="text-gray-300 w-full ml-8">No patient selected</p>
            )}
          </div>
        </div>
      </Popover>
      <PopoverDoctorList
        anchorElDoctor={anchorElDoctor}
        setAnchorElDoctor={setAnchorElDoctor}
        searchDoctor={searchDoctor}
        setSearchDoctor={setSearchDoctor}
        doctorArr={doctorArr}
        setDoctorArr={setDoctorArr}
        debounceDoctor={debounceDoctor}
      />
      <PopoverPatientList
        anchorElPatient={anchorElPatient}
        setAnchorElPatient={setAnchorElPatient}
        searchPatient={searchPatient}
        setSearchPatient={setSearchPatient}
        patientArr={patientArr}
        setPatientArr={setPatientArr}
        debouncePatient={debouncePatient}
      />
    </>
  );
};

export default PrescriptionList;
