import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Autocomplete,
  InputAdornment,
  IconButton,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { Delete as DeleteIcon, Close as CloseIcon } from "@mui/icons-material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useQuery, useMutation } from "@tanstack/react-query";
import moment from "moment";
import {
  fetchProductList,
  getPlatform,
  updateStocksPrice,
  fetchUpdateStocks,
} from "../../../../services/InventoryAPI";
import useDebounce from "../../../hooks/useDebounce";
import { IGNORE_TYPE } from "../../../constants/constant";

const FETCH_LIMIT = 10;
const STATUS_PRODUCT = 3;

const toDateApi = (date) => moment(date).format("YYYY-MM-DD");

const UpdateStockHabis = ({ isWarehouse }) => {
  const navigate = useNavigate();

  const [count, setCount] = useState(0);
  const [qtyType, setQtyType] = useState("");
  const [total, setTotal] = useState(0);
  const [type, setType] = useState("");
  const [offset, setOffset] = useState(1);
  const [search, setSearch] = useState("");
  const [value, setValue] = useState(null);
  const [optionValues, setOptionValues] = useState([]);
  const [open, setOpen] = useState(false);
  const [qty, setQty] = useState([]);
  const [typeBuffer, setTypeBuffer] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const debounce = useDebounce(search, 500);

  const { data: platforms } = useQuery({
    queryKey: ["platform"],
    queryFn: () => getPlatform(),
    keepPreviousData: true,
  });

  const {
    mutate: mutateActiveProducts,
    data: products,
    isLoading,
  } = useMutation({
    mutationFn: fetchProductList,
  });

  const { mutate: mutateUpdateStock } = useMutation({
    mutationFn: updateStocksPrice,
    onSuccess: () =>
      navigate(
        `${isWarehouse ? "/central-warehouse" : ""}/inventory/produk-aktif`
      ),
  });

  const { mutate: mutateFetchUpdateStock, data: updateStocksData } =
    useMutation({
      mutationFn: fetchUpdateStocks,
    });

  const formik = useFormik({
    initialValues: {
      warehouse: isWarehouse ? isWarehouse : false,
      id:
        updateStocksData && updateStocksData !== undefined
          ? updateStocksData[0].product_id
          : "",
      stock:
        updateStocksData && updateStocksData !== undefined
          ? updateStocksData[0].stock
          : "",
      sell_price:
        updateStocksData && updateStocksData !== undefined
          ? updateStocksData[0].sell_price
          : 0,
      sell_price_sekunder:
        updateStocksData && updateStocksData !== undefined
          ? updateStocksData[0].sell_price_sekunder
          : 0,
      sell_price_tersier:
        updateStocksData && updateStocksData !== undefined
          ? updateStocksData[0].sell_price_tersier
          : 0,
      pharmacy_net_price:
        updateStocksData && updateStocksData !== undefined
          ? updateStocksData[0].pharmacy_net_price
          : 0,
      platform_price:
        updateStocksData &&
        updateStocksData !== undefined &&
        updateStocksData[0]?.platform !== null
          ? updateStocksData[0].platform
          : [],
      batch: [{ quantity: 0, number_batch: "", expired_date: "" }],
      price_grosir:
        updateStocksData && updateStocksData !== undefined
          ? updateStocksData.price_grosir
          : [],
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      mutateUpdateStock([values]);
    },
  });

  const intersection = new Set(
    formik.values.platform_price.map(({ platform_id }) => platform_id)
  );
  const result = platforms?.filter(({ id }) => !intersection.has(id));

  const handleAddPlatform = useCallback(() => {
    if (
      platforms &&
      platforms !== undefined &&
      formik.values.platform_price !== null
    ) {
      formik.setFieldValue("platform_price", [
        ...formik.values.platform_price,
        {
          platform_id: result[0]?.id,
          name: result[0]?.name,
          price: "",
          is_active: true,
        },
      ]);
    } else {
      formik.setFieldValue("platform_price", []);
    }
  }, [formik.values.platform_price, result]);

  const isValid = useMemo(() => {
    const isEmpty = (str) => str.trim() === "";
    if (count > 0)
      return formik.values.batch.some(
        (data) => isEmpty(data.number_batch) || isEmpty(data.expired_date)
      );
    else return false;
  }, [formik.values.batch, count]);

  const isEmpty = (str) => str.trim() === "";

  const finalStock = useMemo(() => {
    if (qtyType === typeBuffer.typeUnit) {
      return count;
    }
    if (qtyType === typeBuffer.typeStrip) {
      if (IGNORE_TYPE.includes(typeBuffer.typeUnit)) {
        return count;
      } else {
        return count * qty.totalUnit;
      }
    }
    if (qtyType === typeBuffer.typeBox) {
      if (IGNORE_TYPE.includes(typeBuffer.typeUnit)) {
        return count * qty.totalStrip;
      } else {
        return count * qty.totalUnit * qty.totalStrip;
      }
    }
    return 0;
  }, [count, qty, qtyType, typeBuffer]);

  useEffect(() => {
    if (open)
      mutateActiveProducts({
        offset,
        limit: FETCH_LIMIT,
        status: STATUS_PRODUCT,
        type,
        name: debounce,
        warehouse: isWarehouse ? isWarehouse : false,
      });
  }, [offset, debounce, type, open]);

  useEffect(() => {
    setOptionValues(
      products && products.product_data !== null
        ? products.product_data.map((p) => ({
            id: p.product_id,
            name: p.product_name,
          }))
        : []
    );
  }, [products]);

  useEffect(() => {
    setQty(
      updateStocksData && updateStocksData !== undefined
        ? {
            totalBox: updateStocksData[0].packaging?.total_box,
            totalStrip: updateStocksData[0].packaging?.total_strip,
            totalUnit: updateStocksData[0].packaging?.total_unit,
          }
        : {}
    );
    setTypeBuffer(
      updateStocksData && updateStocksData !== undefined
        ? {
            typeBox: updateStocksData[0].packaging?.type_box,
            typeStrip: updateStocksData[0].packaging?.type_strip,
            typeUnit: updateStocksData[0].packaging?.type_unit,
          }
        : {}
    );
  }, [updateStocksData]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="container mx-auto  mt-16 lg:mt-0">
        <h1 className="font-semibold text-[20px] lg:text-[30px] mb-3">
          Update Stock dan Harga
        </h1>
        <div className="flex text-blue-500 gap-2">
          <p
            className="hover:cursor-pointer"
            onClick={() =>
              navigate(
                `${
                  isWarehouse ? "/central-warehouse" : ""
                }/inventory/produk-aktif`
              )
            }
          >
            Produk Aktif
          </p>
          <p>/</p>
          <p>Update Stock & Harga</p>
        </div>

        <div className="grid grid-cols-1 mb-20 mt-5">
          <Autocomplete
            fullWidth
            loading={isLoading}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            disablePortal
            options={optionValues}
            value={value}
            onChange={(_, inputValue) => {
              setValue(inputValue);
              setOptionValues([]);
              mutateFetchUpdateStock({
                productId: inputValue !== null ? [inputValue.id] : "",
                warehouse: isWarehouse ? isWarehouse : false,
              });
            }}
            onInputChange={(_, onInputValue, reason) => {
              if (reason === "input") {
                setSearch(onInputValue);
                setOptionValues([]);
              }
            }}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {option.name}
                </li>
              );
            }}
            id="combo-box-demo"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Produk"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {isLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
          <p className="font-bold text-[20px] mt-4">Update Stock</p>
          <hr className="border-2 border-black mt-2" />
          {updateStocksData && updateStocksData !== undefined ? (
            <>
              {updateStocksData.map((data, index) => (
                <React.Fragment key={index}>
                  <div className="flex flex-wrap gap-2 mt-4">
                    <p>Stock saat ini :</p>
                    <p className="font-bold">{data.stock}</p>
                    {IGNORE_TYPE.includes(typeBuffer.typeUnit) ? (
                      <p className="font-bold">{typeBuffer.typeStrip}</p>
                    ) : (
                      <p className="font-bold">{typeBuffer.typeUnit}</p>
                    )}
                  </div>
                  <div className="flex flex-wrap gap-2 mt-4 items-center gap-3">
                    <p>Tambah Stock</p>
                    <div className="flex flex-row">
                      <input
                        type="text"
                        className="border rounded-lg w-[69px] p-3"
                        value={count}
                        disabled={qtyType.length === 0}
                        onChange={(e) => {
                          let numericValue = e.target.value;
                          if (numericValue === "") {
                            numericValue = "0";
                          }
                          setCount(
                            parseInt(numericValue.replace(/[^0-9]/g, ""), 10)
                          );
                          if (qtyType === typeBuffer?.typeUnit) {
                            formik.setFieldValue(
                              "stock",
                              data.stock +
                                parseInt(
                                  numericValue.replace(/[^0-9]/g, ""),
                                  10
                                )
                            );
                          }
                          if (qtyType === typeBuffer?.typeStrip) {
                            if (IGNORE_TYPE.includes(typeBuffer.typeUnit)) {
                              formik.setFieldValue(
                                "stock",
                                data.stock +
                                  parseInt(
                                    numericValue.replace(/[^0-9]/g, ""),
                                    10
                                  )
                              );
                            } else {
                              formik.setFieldValue(
                                "stock",
                                data.stock +
                                  parseInt(
                                    numericValue.replace(/[^0-9]/g, ""),
                                    10
                                  ) *
                                    qty.totalUnit
                              );
                            }
                          }
                          if (qtyType === typeBuffer.typeBox) {
                            if (IGNORE_TYPE.includes(typeBuffer.typeUnit)) {
                              formik.setFieldValue(
                                "stock",
                                data.stock +
                                  parseInt(
                                    numericValue.replace(/[^0-9]/g, ""),
                                    10
                                  ) *
                                    qty.totalStrip
                              );
                            } else {
                              formik.setFieldValue(
                                "stock",
                                data.stock +
                                  parseInt(
                                    numericValue.replace(/[^0-9]/g, ""),
                                    10
                                  ) *
                                    qty.totalUnit *
                                    qty.totalStrip
                              );
                            }
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap gap-2 mt-4 mb-4">
                    <p>Stock Akhir :</p>
                    <p className="font-bold">{formik.values.stock}</p>
                    {IGNORE_TYPE.includes(data.packaging.type_unit) ? (
                      <p className="font-bold">{data.packaging?.type_strip}</p>
                    ) : (
                      <p className="font-bold">{data.packaging?.type_unit}</p>
                    )}
                  </div>
                  <FormControl fullWidth>
                    <InputLabel>Tipe Kemasan</InputLabel>
                    <Select
                      label="Tipe Kemasan"
                      onChange={(e) => setQtyType(e.target.value)}
                      value={qtyType}
                    >
                      <MenuItem
                        value={data.packaging?.type_box}
                        onClick={() => {
                          if (formik.values.stock === 0) return;
                          if (IGNORE_TYPE.includes(typeBuffer.typeUnit)) {
                            formik.setFieldValue(
                              "stock",
                              data.stock + count * qty.totalStrip
                            );
                          } else {
                            formik.setFieldValue(
                              "stock",
                              data.stock +
                                count * qty.totalUnit * qty.totalStrip
                            );
                          }
                          setTotal(data.packaging?.total_unit);
                        }}
                        // disabled={qtyType !== data.packaging.total_unit}
                      >
                        {data.packaging?.type_box}
                      </MenuItem>
                      <MenuItem
                        value={data.packaging?.type_strip}
                        onClick={() => {
                          if (formik.values.stock === 0) return;
                          if (IGNORE_TYPE.includes(typeBuffer.typeUnit)) {
                            formik.setFieldValue("stock", data.stock + count);
                          } else {
                            formik.setFieldValue(
                              "stock",
                              data.stock + count * qty.totalUnit
                            );
                          }
                          setTotal(data.packaging?.total_strip);
                        }}
                        // disabled={qtyType !== data.packaging.total_strip}
                      >
                        {data.packaging?.type_strip}
                      </MenuItem>
                      {IGNORE_TYPE.includes(
                        data.packaging?.type_unit
                      ) ? null : (
                        <MenuItem
                          value={data.packaging?.type_unit}
                          onClick={() => {
                            if (formik.values.stock === 0) return;
                            formik.setFieldValue("stock", data.stock + count);
                            setTotal(data.packaging?.total_box);
                          }}
                          // disabled={qtyType !== data.packaging.total_box}
                        >
                          {data.packaging?.type_unit}
                        </MenuItem>
                      )}
                      <MenuItem
                        value={""}
                        sx={{ display: "none" }}
                        disabled
                      ></MenuItem>
                    </Select>
                  </FormControl>
                  {formik.values.batch.map((b, index) => (
                    <React.Fragment key={index}>
                      <div className="flex justify-evenly mt-4">
                        <TextField
                          sx={{ width: "40%" }}
                          id="outlined-basic"
                          label="Nomor Batch"
                          variant="outlined"
                          value={b.number_batch}
                          onChange={(e) =>
                            formik.setFieldValue(
                              "batch",
                              formik.values.batch.map((b1, idx) => {
                                return index === idx
                                  ? { ...b, number_batch: e.target.value }
                                  : b1;
                              })
                            )
                          }
                        />
                        <TextField
                          sx={{ width: "30%", marginX: 3 }}
                          id="outlined-basic"
                          label="Stock"
                          variant="outlined"
                          value={
                            b.quantity /
                            (data.packaging?.total_box *
                              data.packaging?.total_strip *
                              data.packaging?.total_unit)
                          }
                          onChange={(e) => {
                            let numericValue = e.target.value;
                            if (numericValue === "") {
                              numericValue = "0";
                            }
                            formik.setFieldValue(
                              "batch",
                              formik.values.batch.map((b1, idx) => {
                                return index === idx
                                  ? {
                                      ...b,
                                      quantity:
                                        parseInt(
                                          numericValue.replace(/[^0-9]/g, null),
                                          10
                                        ) *
                                        data.packaging?.total_box *
                                        data.packaging?.total_strip *
                                        data.packaging?.total_unit,
                                    }
                                  : b1;
                              })
                            );
                          }}
                        />
                        <DesktopDatePicker
                          label="Tanggal Expired"
                          sx={{ width: "100%" }}
                          value={b.expired_date !== "" ? b.expired_date : null}
                          onChange={(newExpiredDate) =>
                            formik.setFieldValue(
                              "batch",
                              formik.values.batch.map((b1, idx) => {
                                return index === idx
                                  ? {
                                      ...b,
                                      expired_date: toDateApi(newExpiredDate),
                                    }
                                  : b1;
                              })
                            )
                          }
                          renderInput={(params) => {
                            return <TextField {...params} />;
                          }}
                          disablePast={true}
                        />
                        <button
                          type="button"
                          className="ml-[10px]"
                          onClick={() => {
                            if (formik.values.batch.length === 1) {
                              formik.setFieldValue("batch", []);
                            } else {
                              formik.setFieldValue("batch", [
                                ...formik.values.batch.slice(0, index),
                                ...formik.values.batch.slice(index + 1),
                              ]);
                            }
                          }}
                        >
                          <DeleteIcon sx={{ color: "black" }} />
                        </button>
                      </div>
                      <div>
                        {formik.values.batch.reduce((acc, num) => {
                          return (
                            acc +
                            num.quantity /
                              (data.packaging.total_box *
                                data.packaging.total_strip *
                                data.packaging.total_unit)
                          );
                        }, 0) > count && formik.values.batch.length >= count ? (
                          <div className="flex">
                            <p className="text-red-500">
                              Stock melebihi batas maksimal
                            </p>
                          </div>
                        ) : null}
                      </div>
                    </React.Fragment>
                  ))}
                  <p
                    className="text-blue-500 hover:underline hover:text-blue-500 cursor-pointer mt-4 text-end"
                    onClick={() => {
                      if (
                        data.packaging.total_box === null &&
                        data.packaging.total_strip === null &&
                        data.packaging.total_unit === null
                      )
                        return;
                      else if (
                        formik.values.batch.length === count ||
                        formik.values.batch.reduce((acc, num) => {
                          return (
                            acc +
                            num.quantity /
                              (data.packaging.total_box *
                                data.packaging.total_strip *
                                data.packaging.total_unit)
                          );
                        }, 0) >= count
                      )
                        return;
                      formik.setFieldValue("batch", [
                        ...formik.values.batch,
                        {
                          quantity: 0,
                          number_batch: "",
                          expired_date: "",
                        },
                      ]);
                    }}
                  >
                    +Tambah Batch lain
                  </p>
                  <TextField
                    id="outlined-basic"
                    fullWidth
                    label="HNA"
                    autoComplete="off"
                    variant="outlined"
                    value={formik.values.pharmacy_net_price}
                    className="mt-3"
                    onChange={(e) => {
                      let numericValue = e.target.value;
                      if (numericValue === "") {
                        numericValue = "0";
                      }
                      formik.setFieldValue(
                        "pharmacy_net_price",
                        parseInt(numericValue.replace(/[^0-9]/g, ""), 10)
                      );
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">Rp.</InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    id="outlined-basic"
                    fullWidth
                    label="Harga Jual Primer"
                    autoComplete="off"
                    variant="outlined"
                    value={formik.values.sell_price}
                    className="mt-3"
                    onChange={(e) => {
                      let numericValue = e.target.value;
                      if (numericValue === "") {
                        numericValue = "0";
                      }
                      formik.setFieldValue(
                        "sell_price",
                        parseInt(numericValue.replace(/[^0-9]/g, null), 10)
                      );
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">Rp.</InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    id="outlined-basic"
                    fullWidth
                    label="Harga Jual Sekunder"
                    autoComplete="off"
                    variant="outlined"
                    value={formik.values.sell_price_sekunder}
                    className="mt-3"
                    onChange={(e) => {
                      let numericValue = e.target.value;
                      if (numericValue === "") {
                        numericValue = "0";
                      }
                      formik.setFieldValue(
                        "sell_price_sekunder",
                        parseInt(numericValue.replace(/[^0-9]/g, ""), 10)
                      );
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">Rp.</InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    id="outlined-basic"
                    fullWidth
                    label="Harga Jual Tersier"
                    autoComplete="off"
                    variant="outlined"
                    value={formik.values.sell_price_tersier}
                    className="mt-3"
                    onChange={(e) => {
                      let numericValue = e.target.value;
                      if (numericValue === "") {
                        numericValue = "0";
                      }
                      formik.setFieldValue(
                        "sell_price_tersier",
                        parseInt(numericValue.replace(/[^0-9]/g, ""), 10)
                      );
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">Rp.</InputAdornment>
                      ),
                    }}
                  />
                </React.Fragment>
              ))}
              <p
                className="text-blue-500 hover:underline hover:text-blue-500 cursor-pointer mt-4 text-end"
                onClick={handleAddPlatform}
              >
                +Tambah Platform lain
              </p>
            </>
          ) : (
            <>
              <div className="flex flex-wrap gap-2 mt-4">
                <p>Stock saat ini :</p>
                <p className="font-bold"></p>
              </div>
              <div className="flex flex-wrap gap-2 mt-4 items-center gap-3">
                <p>Tambah Stock</p>
                <div className="flex flex-row">
                  <input
                    type="text"
                    className="border rounded-lg w-[69px] p-3"
                    disabled
                  />
                </div>
              </div>
              <div className="flex flex-wrap gap-2 mt-4 mb-4">
                <p>Stock Akhir :</p>
              </div>
              <FormControl fullWidth>
                <Select
                  disabled
                  onChange={(e) => setQtyType(e.target.value)}
                  value={qtyType}
                >
                  <MenuItem
                    value={""}
                    sx={{ display: "none" }}
                    disabled
                  ></MenuItem>
                </Select>
              </FormControl>

              <p className="text-blue-500 hover:underline hover:text-blue-500 cursor-pointer mt-4 text-end">
                +Tambah Batch lain
              </p>

              <p className="font-bold text-[20px] mt-4">Update Stock</p>
              <hr className="border-2 border-black mt-2" />
              <TextField
                id="outlined-basic"
                label="Harga Jual Primer"
                disabled
                autoComplete="off"
                variant="outlined"
                className="mt-3"
                placeholder="Rp."
              />
              <TextField
                id="outlined-basic"
                label="Harga Jual Sekunder"
                disabled
                autoComplete="off"
                variant="outlined"
                className="mt-3"
                placeholder="Rp."
              />
              <TextField
                id="outlined-basic"
                label="Harga Jual Tersier"
                disabled
                autoComplete="off"
                variant="outlined"
                className="mt-3"
                placeholder="Rp."
              />
              <p className="text-blue-500 hover:underline hover:text-blue-500 cursor-pointer mt-4 text-end">
                +Tambah Platform lain
              </p>
            </>
          )}
          {platforms &&
          platforms !== undefined &&
          formik.values.platform_price?.length > 0 &&
          formik.values.platform_price !== null ? (
            <>
              {formik.values.platform_price.map((plat, index) => {
                return (
                  <div
                    className="flex justify-center items-center mt-[20px]"
                    key={plat.platform_id}
                  >
                    <FormControl fullWidth sx={{ marginRight: 1 }}>
                      <InputLabel id="demo-simple-select-label">
                        Kategori
                      </InputLabel>
                      <Select
                        id="add-category"
                        label="Kategori"
                        value={plat.platform_id}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "platform_price",
                            formik.values.platform_price.map((p1) => {
                              return p1.platform_id === plat.platform_id
                                ? {
                                    ...plat,
                                    platform_id: e.target.value,
                                  }
                                : p1;
                            })
                          );
                        }}
                      >
                        {platforms.map((p) => {
                          return (
                            <MenuItem
                              key={p.id}
                              value={p.id}
                              disabled={
                                formik.values.platform_price.find(
                                  (f) => f.id === p.id
                                )
                                  ? true
                                  : false
                              }
                              sx={{
                                display: formik.values.platform_price.find(
                                  (f) => f.id === p.id
                                )
                                  ? "none"
                                  : null,
                              }}
                            >
                              {p.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <TextField
                      id="outlined-basic"
                      fullWidth
                      label="Harga"
                      autoComplete="off"
                      variant="outlined"
                      value={plat.price}
                      onChange={(e) => {
                        let numericValue = e.target.value;
                        if (numericValue === "") {
                          numericValue = "0";
                        }
                        formik.setFieldValue(
                          "platform_price",
                          formik.values.platform_price.map((p1) => {
                            return p1.platform_id === plat.platform_id
                              ? {
                                  ...plat,
                                  price: parseInt(
                                    numericValue.replace(/[^0-9]/g, ""),
                                    10
                                  ),
                                }
                              : p1;
                          })
                        );
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">Rp.</InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              edge="end"
                              onClick={() => {
                                if (formik.values.platform_price.length === 1)
                                  formik.setFieldValue("platform_price", []);
                                else
                                  formik.setFieldValue("platform_price", [
                                    ...formik.values.platform_price.slice(
                                      0,
                                      index
                                    ),
                                    ...formik.values.platform_price.slice(
                                      index + 1
                                    ),
                                  ]);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                );
              })}
            </>
          ) : null}
        </div>
        <div className="grid grid-cols-2 mt-10 gap-12">
          <button
            className="bg-red-700 text-white font-semibold p-3 rounded-lg"
            onClick={() =>
              navigate(
                `${
                  isWarehouse ? "/central-warehouse" : ""
                }/inventory/produk-aktif`
              )
            }
          >
            Batal
          </button>
          <button
            className="bg-orange-400 text-white font-semibold p-3 rounded-lg disabled:bg-gray-500"
            // disabled={
            //   formik.values.batch
            //     .map((data) => data.number_batch)
            //     .includes("") ||
            //   formik.values.batch
            //     .map((data) => data.expired_date)
            //     .includes("") ||
            //   formik.values.batch.reduce((acc, num) => {
            //     return (
            //       acc +
            //       num.quantity / (qty.totalBox * qty.totalStrip * qty.totalUnit)
            //     );
            //   }, 0) !== count ||
            //   formik.values.batch.reduce((acc, num) => {
            //     return (
            //       acc +
            //       num.quantity / (qty.totalBox * qty.totalStrip * qty.totalUnit)
            //     );
            //   }, 0) === 0
            // }
            disabled={isValid || value === null}
            type="submit"
          >
            Simpan
          </button>
        </div>
      </div>
      <Snackbar
        open={Boolean(errorMessage)}
        onClose={() => setErrorMessage("")}
        message={errorMessage}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setErrorMessage("")}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </form>
  );
};

export default UpdateStockHabis;
