import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Autocomplete,
  InputAdornment,
  IconButton,
  CircularProgress,
  Snackbar,
  Switch,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { Delete as DeleteIcon, Close as CloseIcon } from "@mui/icons-material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useQuery, useMutation } from "@tanstack/react-query";
import moment from "moment";
import {
  fetchProductList,
  getPlatform,
  updateStocksPrice,
  fetchUpdateStocks,
} from "../../../../services/InventoryAPI";
import useDebounce from "../../../hooks/useDebounce";
import { IGNORE_TYPE } from "../../../constants/constant";

const FETCH_LIMIT = 10;
const STATUS_PRODUCT = 1;

const toDateApi = (date) => moment(date).format("YYYY-MM-DD");

const UpdateStock = ({ isWarehouse }) => {
  const navigate = useNavigate();

  const [count, setCount] = useState(0);
  const [qtyType, setQtyType] = useState("");
  const [offset, setOffset] = useState(1);
  const [search, setSearch] = useState("");
  const [value, setValue] = useState(null);
  const [optionValues, setOptionValues] = useState([]);
  const [open, setOpen] = useState(false);
  const [qty, setQty] = useState({
    totalBox: 0,
    totalStrip: 0,
    totalUnit: 0,
  });
  const [typeBuffer, setTypeBuffer] = useState({
    typeBox: "",
    typeStrip: "",
    typeUnit: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const debounce = useDebounce(search, 500);

  const { data: platforms } = useQuery({
    queryKey: ["platform"],
    queryFn: () => getPlatform(),
    keepPreviousData: true,
  });

  const { mutate: mutateActiveProducts, isLoading } = useMutation({
    mutationFn: fetchProductList,
    onSuccess: (data) => {
      setOptionValues(
        data && data.product_data !== null
          ? data.product_data.map((p) => ({
              id: p.product_id,
              name: p.product_name,
            }))
          : []
      );
    },
  });

  const { mutate: mutateUpdateStock } = useMutation({
    mutationFn: updateStocksPrice,
    onSuccess: () =>
      navigate(
        `${isWarehouse ? "/central-warehouse" : ""}/inventory/produk-aktif`
      ),
  });

  const { mutate: mutateFetchUpdateStock, data: updateStocksData } =
    useMutation({
      mutationFn: fetchUpdateStocks,
      onSuccess: (data) => {
        setQty(
          data && data !== undefined
            ? {
                totalBox: data[0].packaging?.total_box,
                totalStrip: data[0].packaging?.total_strip,
                totalUnit: data[0].packaging?.total_unit,
              }
            : {}
        );
        setTypeBuffer(
          data && data !== undefined
            ? {
                typeBox: data[0].packaging?.type_box,
                typeStrip: data[0].packaging?.type_strip,
                typeUnit: data[0].packaging?.type_unit,
              }
            : {}
        );
      },
    });

  const pharmacyNetPriceFinal = (
    discountPercent,
    pharmacyNetPriceReal,
    isTaxEnabled
  ) => {
    let discount = pharmacyNetPriceReal * (discountPercent / 100);
    let value = (pharmacyNetPriceReal - discount) * 0.11;
    if (!isTaxEnabled) {
      value = 0;
    }
    return pharmacyNetPriceReal - discount + value;
  };

  const formik = useFormik({
    initialValues: {
      id:
        updateStocksData && updateStocksData !== undefined
          ? updateStocksData[0].product_id
          : "",
      name_product:
        updateStocksData && updateStocksData !== undefined
          ? updateStocksData[0].product_name
          : "",
      stock:
        updateStocksData && updateStocksData !== undefined
          ? updateStocksData[0].stock
          : "",
      sell_price:
        updateStocksData && updateStocksData !== undefined
          ? updateStocksData[0].sell_price
          : 0,
      sell_price_sekunder:
        updateStocksData && updateStocksData !== undefined
          ? updateStocksData[0].sell_price_sekunder
          : 0,
      sell_price_tersier:
        updateStocksData && updateStocksData !== undefined
          ? updateStocksData[0].sell_price_tersier
          : 0,
      pharmacy_net_price_real:
        updateStocksData && updateStocksData !== undefined
          ? updateStocksData[0].pharmacy_net_price_real
          : 0,
      pharmacy_net_price_discount:
        updateStocksData && updateStocksData !== undefined
          ? updateStocksData[0].pharmacy_net_price_discount
          : 0,
      pharmacy_net_price_ppn:
        updateStocksData && updateStocksData !== undefined
          ? updateStocksData[0].pharmacy_net_price_ppn
          : 0,
      pharmacy_net_price: 0,
      platform_price:
        updateStocksData &&
        updateStocksData !== undefined &&
        updateStocksData[0]?.platform !== null
          ? updateStocksData[0].platform
          : [],
      batch: [{ quantity: 0, number_batch: "", expired_date: "" }],
      price_grosir:
        updateStocksData &&
        updateStocksData !== undefined &&
        updateStocksData[0]?.price_grosir !== null
          ? updateStocksData[0]?.price_grosir
          : [],
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      mutateUpdateStock({
        warehouse: isWarehouse ? isWarehouse : false,
        data: [
          {
            ...values,
            pharmacy_net_price: pharmacyNetPriceFinal(
              values.pharmacy_net_price_discount,
              values.pharmacy_net_price_real,
              values.pharmacy_net_price_ppn
            ),
            batch: values.batch.map((data) => {
              if (qtyType === typeBuffer.typeUnit) {
                return {
                  quantity: data.quantity,
                  number_batch: data.number_batch,
                  expired_date: data.expired_date,
                };
              }
              if (qtyType === typeBuffer.typeStrip) {
                if (IGNORE_TYPE.includes(typeBuffer.typeUnit)) {
                  return {
                    quantity: data.quantity,
                    number_batch: data.number_batch,
                    expired_date: data.expired_date,
                  };
                } else {
                  return {
                    quantity: data.quantity * qty.totalUnit,
                    number_batch: data.number_batch,
                    expired_date: data.expired_date,
                  };
                }
              }
              if (qtyType === typeBuffer.typeBox) {
                if (IGNORE_TYPE.includes(typeBuffer.typeUnit)) {
                  return {
                    quantity: data.quantity * qty.totalStrip,
                    number_batch: data.number_batch,
                    expired_date: data.expired_date,
                  };
                } else {
                  return {
                    quantity: data.quantity * qty.totalStrip * qty.totalUnit,
                    number_batch: data.number_batch,
                    expired_date: data.expired_date,
                  };
                }
              }
              return {
                quantity: data.quantity,
                number_batch: data.number_batch,
                expired_date: data.expired_date,
              };
            }),
          },
        ],
      });
    },
  });

  const intersection = new Set(
    formik.values.platform_price.map(({ platform_id }) => platform_id)
  );
  const result = platforms?.filter(({ id }) => !intersection.has(id));

  const handleAddPlatform = useCallback(() => {
    if (
      platforms &&
      platforms !== undefined &&
      formik.values.platform_price !== null
    ) {
      formik.setFieldValue("platform_price", [
        ...formik.values.platform_price,
        {
          platform_id: result[0]?.id,
          name: result[0]?.name,
          price: "",
          is_active: true,
        },
      ]);
    } else {
      formik.setFieldValue("platform_price", []);
    }
  }, [formik.values.platform_price, result]);

  const isValid = useMemo(() => {
    const isEmpty = (str) => str.trim() === "";
    if (count > 0)
      return formik.values.batch.some(
        (data) => isEmpty(data.number_batch) || isEmpty(data.expired_date)
      );
    else return false;
  }, [formik.values.batch, count]);

  useEffect(() => {
    if (open)
      mutateActiveProducts({
        offset,
        limit: FETCH_LIMIT,
        status: STATUS_PRODUCT,
        type: "",
        name: debounce,
        warehouse: isWarehouse ? isWarehouse : false,
      });
  }, [offset, debounce, open, mutateActiveProducts]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="container mx-auto  mt-16 lg:mt-0">
        <h1 className="font-semibold text-[20px] lg:text-[30px] mb-3">
          Update Stock dan Harga
        </h1>
        <div className="flex text-blue-500 gap-2">
          <p
            className="hover:cursor-pointer"
            onClick={() =>
              navigate(
                `${
                  isWarehouse ? "/central-warehouse" : ""
                }/inventory/produk-aktif`
              )
            }
          >
            Produk Aktif
          </p>
          <p>/</p>
          <p>Update Stock & Harga</p>
        </div>

        <div className="grid grid-cols-1 mb-20 mt-5">
          <Autocomplete
            fullWidth
            loading={isLoading}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            disablePortal
            options={optionValues}
            value={value}
            onChange={(_, inputValue) => {
              setValue(inputValue);
              setOptionValues([]);
              mutateFetchUpdateStock({
                productId: inputValue !== null ? [inputValue.id] : "",
                warehouse: isWarehouse ? isWarehouse : false,
              });
            }}
            onInputChange={(_, onInputValue, reason) => {
              if (reason === "input") {
                setSearch(onInputValue);
                setOptionValues([]);
              }
            }}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {option.name}
                </li>
              );
            }}
            id="combo-box-demo"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Produk"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {isLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
          <p className="font-bold text-[20px] mt-4">Update Stock</p>
          <hr className="border-2 border-black mt-2" />
          {updateStocksData && updateStocksData !== undefined ? (
            <>
              {updateStocksData.map((data, index) => (
                <React.Fragment key={index}>
                  <div className="flex flex-wrap gap-2 mt-4">
                    <p>Stock saat ini :</p>
                    <p className="font-bold">{data.stock}</p>
                    {IGNORE_TYPE.includes(typeBuffer.typeUnit) ? (
                      <p className="font-bold">{typeBuffer.typeStrip}</p>
                    ) : (
                      <p className="font-bold">{typeBuffer.typeUnit}</p>
                    )}
                  </div>
                  <div className="flex flex-wrap gap-2 mt-4 items-center gap-3">
                    <p>Tambah Stock</p>
                    <div className="flex flex-row">
                      <input
                        type="text"
                        className="border rounded-lg w-[69px] p-3"
                        value={count}
                        disabled={qtyType.length === 0}
                        onChange={(e) => {
                          let numericValue = e.target.value;
                          if (numericValue === "") {
                            numericValue = "0";
                          }
                          if (qtyType === typeBuffer.typeUnit) {
                            formik.setFieldValue(
                              "stock",
                              parseInt(
                                numericValue.replace(/[^0-9]/g, ""),
                                10
                              ) + formik.values.stock
                            );
                          }
                          if (qtyType === typeBuffer.typeStrip) {
                            if (IGNORE_TYPE.includes(typeBuffer.typeUnit)) {
                              formik.setFieldValue(
                                "stock",
                                parseInt(
                                  numericValue.replace(/[^0-9]/g, ""),
                                  10
                                ) + formik.values.stock
                              );
                            } else {
                              formik.setFieldValue(
                                "stock",
                                parseInt(
                                  numericValue.replace(/[^0-9]/g, ""),
                                  10
                                ) *
                                  qty.totalUnit +
                                  formik.values.stock
                              );
                            }
                          }
                          if (qtyType === typeBuffer.typeBox) {
                            if (IGNORE_TYPE.includes(typeBuffer.typeUnit)) {
                              formik.setFieldValue(
                                "stock",
                                parseInt(
                                  numericValue.replace(/[^0-9]/g, ""),
                                  10
                                ) *
                                  qty.totalStrip +
                                  formik.values.stock
                              );
                            } else {
                              formik.setFieldValue(
                                "stock",
                                parseInt(
                                  numericValue.replace(/[^0-9]/g, ""),
                                  10
                                ) *
                                  qty.totalStrip *
                                  qty.totalUnit +
                                  formik.values.stock
                              );
                            }
                          }
                          setCount(
                            parseInt(numericValue.replace(/[^0-9]/g, ""), 10)
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap gap-2 mt-4 mb-4">
                    <p>Stock Akhir :</p>
                    <p className="font-bold">{formik.values.stock}</p>
                    {IGNORE_TYPE.includes(data.packaging.type_unit) ? (
                      <p className="font-bold">{data.packaging?.type_strip}</p>
                    ) : (
                      <p className="font-bold">{data.packaging?.type_unit}</p>
                    )}
                  </div>
                  <FormControl fullWidth>
                    <InputLabel>Tipe Kemasan</InputLabel>
                    <Select
                      label="Tipe Kemasan"
                      onChange={(e) => setQtyType(e.target.value)}
                      value={qtyType}
                    >
                      <MenuItem
                        value={data.packaging?.type_box}
                        onClick={() => {
                          if (formik.values.stock === 0) return;
                          if (IGNORE_TYPE.includes(typeBuffer.typeUnit)) {
                            formik.setFieldValue(
                              "stock",
                              data.stock + count * qty.totalStrip
                            );
                          } else {
                            formik.setFieldValue(
                              "stock",
                              data.stock +
                                count * qty.totalUnit * qty.totalStrip
                            );
                          }
                        }}
                        // disabled={qtyType !== data.packaging.total_unit}
                      >
                        {data.packaging?.type_box}
                      </MenuItem>
                      <MenuItem
                        value={data.packaging?.type_strip}
                        onClick={() => {
                          if (formik.values.stock === 0) return;
                          if (IGNORE_TYPE.includes(typeBuffer.typeUnit)) {
                            formik.setFieldValue("stock", data.stock + count);
                          } else {
                            formik.setFieldValue(
                              "stock",
                              data.stock + count * qty.totalUnit
                            );
                          }
                        }}
                        // disabled={qtyType !== data.packaging.total_strip}
                      >
                        {data.packaging?.type_strip}
                      </MenuItem>
                      {IGNORE_TYPE.includes(
                        data.packaging?.type_unit
                      ) ? null : (
                        <MenuItem
                          value={data.packaging?.type_unit}
                          onClick={() => {
                            if (formik.values.stock === 0) return;
                            formik.setFieldValue("stock", data.stock + count);
                          }}
                          // disabled={qtyType !== data.packaging.total_box}
                        >
                          {data.packaging?.type_unit}
                        </MenuItem>
                      )}
                      <MenuItem
                        value={""}
                        sx={{ display: "none" }}
                        disabled
                      ></MenuItem>
                    </Select>
                  </FormControl>
                  {formik.values.batch.map((b, index) => (
                    <React.Fragment key={index}>
                      <div className="flex justify-evenly mt-4">
                        <TextField
                          sx={{ width: "40%" }}
                          id="outlined-basic"
                          label="Nomor Batch"
                          variant="outlined"
                          value={b.number_batch}
                          onChange={(e) =>
                            formik.setFieldValue(
                              "batch",
                              formik.values.batch.map((b1, idx) => {
                                return index === idx
                                  ? { ...b, number_batch: e.target.value }
                                  : b1;
                              })
                            )
                          }
                        />
                        <TextField
                          sx={{ width: "30%", marginX: 3 }}
                          id="outlined-basic"
                          label="Stock"
                          variant="outlined"
                          value={b.quantity}
                          onChange={(e) => {
                            let numericValue = e.target.value;
                            if (numericValue === "") {
                              numericValue = "0";
                            }
                            formik.setFieldValue(
                              "batch",
                              formik.values.batch.map((b1, idx) => {
                                return index === idx
                                  ? {
                                      ...b,
                                      quantity: parseInt(
                                        numericValue.replace(/[^0-9]/g, null),
                                        10
                                      ),
                                    }
                                  : b1;
                              })
                            );
                          }}
                        />
                        <DesktopDatePicker
                          label="Tanggal Expired"
                          sx={{ width: "100%" }}
                          value={b.expired_date !== "" ? b.expired_date : null}
                          onChange={(newExpiredDate) =>
                            formik.setFieldValue(
                              "batch",
                              formik.values.batch.map((b1, idx) => {
                                return index === idx
                                  ? {
                                      ...b,
                                      expired_date: toDateApi(newExpiredDate),
                                    }
                                  : b1;
                              })
                            )
                          }
                          renderInput={(params) => {
                            return <TextField {...params} />;
                          }}
                          disablePast={true}
                        />
                        <button
                          type="button"
                          className="ml-[10px]"
                          onClick={() => {
                            if (formik.values.batch.length === 1) {
                              formik.setFieldValue("batch", []);
                            } else {
                              formik.setFieldValue("batch", [
                                ...formik.values.batch.slice(0, index),
                                ...formik.values.batch.slice(index + 1),
                              ]);
                            }
                          }}
                        >
                          <DeleteIcon sx={{ color: "black" }} />
                        </button>
                      </div>
                      <div>
                        {formik.values.batch.reduce((acc, num) => {
                          return acc + num.quantity;
                        }, 0) > count && formik.values.batch.length >= count ? (
                          <div className="flex">
                            <p className="text-red-500">
                              Stock melebihi batas maksimal
                            </p>
                          </div>
                        ) : null}
                      </div>
                    </React.Fragment>
                  ))}
                  <p
                    className="text-blue-500 hover:underline hover:text-blue-500 cursor-pointer mt-4 text-end"
                    onClick={() => {
                      if (
                        data.packaging.total_box === null &&
                        data.packaging.total_strip === null &&
                        data.packaging.total_unit === null
                      )
                        return;
                      else if (
                        formik.values.batch.length === count ||
                        formik.values.batch.reduce((acc, num) => {
                          return acc + num.quantity;
                        }, 0) >= count
                      )
                        return;
                      formik.setFieldValue("batch", [
                        ...formik.values.batch,
                        {
                          quantity: 0,
                          number_batch: "",
                          expired_date: "",
                        },
                      ]);
                    }}
                  >
                    +Tambah Batch lain
                  </p>
                  <TextField
                    id="outlined-basic"
                    fullWidth
                    label="HNA"
                    autoComplete="off"
                    variant="outlined"
                    value={formik.values.pharmacy_net_price_real}
                    className="mt-3"
                    onChange={(e) => {
                      let numericValue = e.target.value;
                      if (numericValue === "") {
                        numericValue = "0";
                      }
                      formik.setFieldValue(
                        "pharmacy_net_price_real",
                        parseInt(numericValue.replace(/[^0-9]/g, ""), 10)
                      );
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">Rp.</InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    id="outlined-basic"
                    fullWidth
                    sx={{ marginTop: 3 }}
                    label="Discount"
                    variant="outlined"
                    InputProps={
                      ({
                        startAdornment: (
                          <InputAdornment position="start">Rp.</InputAdornment>
                        ),
                      },
                      {
                        endAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                      })
                    }
                    value={formik.values.pharmacy_net_price_discount}
                    onChange={(e) => {
                      let numericValue = e.target.value;
                      if (numericValue === "") {
                        numericValue = "0";
                      }
                      if (
                        Number(numericValue) >= 0 &&
                        Number(numericValue) <= 100
                      )
                        formik.setFieldValue(
                          "pharmacy_net_price_discount",
                          parseInt(numericValue.replace(/[^0-9]/g, ""), 10)
                        );
                    }}
                  />
                  <div className="flex items-center mt-3">
                    <div>
                      <p className="font-bold">PPN 11%</p>
                      <Switch
                        checked={formik.values.pharmacy_net_price_ppn}
                        onClick={() => {
                          formik.setFieldValue(
                            "pharmacy_net_price_ppn",
                            !formik.values.pharmacy_net_price_ppn
                          );
                        }}
                      />
                    </div>
                    <TextField
                      id="outlined-basic"
                      sx={{ width: "20%", marginLeft: 2 }}
                      label="Harga Akhir HNA"
                      autoComplete="off"
                      variant="outlined"
                      value={pharmacyNetPriceFinal(
                        formik.values.pharmacy_net_price_discount,
                        formik.values.pharmacy_net_price_real,
                        formik.values.pharmacy_net_price_ppn
                      )}
                      className="mt-3"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">Rp.</InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  {isWarehouse ? null : (
                    <>
                      {" "}
                      <TextField
                        id="outlined-basic"
                        fullWidth
                        label="Harga Jual Primer"
                        autoComplete="off"
                        variant="outlined"
                        value={formik.values.sell_price}
                        className="mt-3"
                        onChange={(e) => {
                          let numericValue = e.target.value;
                          if (numericValue === "") {
                            numericValue = "0";
                          }
                          formik.setFieldValue(
                            "sell_price",
                            parseInt(numericValue.replace(/[^0-9]/g, null), 10)
                          );
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              Rp.
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        id="outlined-basic"
                        fullWidth
                        label="Harga Jual Sekunder"
                        autoComplete="off"
                        variant="outlined"
                        value={formik.values.sell_price_sekunder}
                        className="mt-3"
                        onChange={(e) => {
                          let numericValue = e.target.value;
                          if (numericValue === "") {
                            numericValue = "0";
                          }
                          formik.setFieldValue(
                            "sell_price_sekunder",
                            parseInt(numericValue.replace(/[^0-9]/g, ""), 10)
                          );
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              Rp.
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        id="outlined-basic"
                        fullWidth
                        label="Harga Jual Tersier"
                        autoComplete="off"
                        variant="outlined"
                        value={formik.values.sell_price_tersier}
                        className="mt-3"
                        onChange={(e) => {
                          let numericValue = e.target.value;
                          if (numericValue === "") {
                            numericValue = "0";
                          }
                          formik.setFieldValue(
                            "sell_price_tersier",
                            parseInt(numericValue.replace(/[^0-9]/g, ""), 10)
                          );
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              Rp.
                            </InputAdornment>
                          ),
                        }}
                      />
                    </>
                  )}
                </React.Fragment>
              ))}

              {isWarehouse ? null : (
                <>
                  {" "}
                  <p
                    className="text-blue-500 hover:underline hover:text-blue-500 cursor-pointer mt-4 text-right w-full"
                    onClick={() => {
                      formik.setFieldValue("price_grosir", [
                        ...formik.values.price_grosir,
                        { minimum: 0, maximum: 0, price_grosirs: 0 },
                      ]);
                    }}
                  >
                    +Tambah harga grosir baru
                  </p>
                  {formik.values.price_grosir.length > 0 ? (
                    <p className="font-bold mt-2 w-full basis-1/3">
                      Harga Grosir
                    </p>
                  ) : null}
                  {formik.values.price_grosir !== null
                    ? formik.values.price_grosir.map((g, index) => {
                        return (
                          <div className="my-2 w-full" key={index}>
                            <div className="w-full flex gap-2 items-center">
                              <div className="w-full flex gap-2 items-center">
                                <TextField
                                  fullWidth
                                  value={g.minimum}
                                  onChange={(e) => {
                                    let numericValue = e.target.value;
                                    if (numericValue === "") {
                                      numericValue = "0";
                                    }
                                    formik.setFieldValue(
                                      "price_grosir",
                                      formik.values.price_grosir.map(
                                        (g1, idx) => {
                                          return index === idx
                                            ? {
                                                ...g,
                                                minimum: parseInt(
                                                  numericValue.replace(
                                                    /[^0-9]/g,
                                                    ""
                                                  ),
                                                  10
                                                ),
                                              }
                                            : g1;
                                        }
                                      )
                                    );
                                  }}
                                />
                                <p>S/D</p>
                                <TextField
                                  fullWidth
                                  value={g.maximum}
                                  onChange={(e) => {
                                    let numericValue = e.target.value;
                                    if (numericValue === "") {
                                      numericValue = "0";
                                    }
                                    formik.setFieldValue(
                                      "price_grosir",
                                      formik.values.price_grosir.map(
                                        (g1, idx) => {
                                          return index === idx
                                            ? {
                                                ...g,
                                                maximum: parseInt(
                                                  numericValue.replace(
                                                    /[^0-9]/g,
                                                    ""
                                                  ),
                                                  10
                                                ),
                                              }
                                            : g1;
                                        }
                                      )
                                    );
                                  }}
                                />
                              </div>
                              <TextField
                                fullWidth
                                id="outlined-basic"
                                variant="outlined"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      Rp.
                                    </InputAdornment>
                                  ),
                                }}
                                value={g.price_grosirs}
                                onChange={(e) => {
                                  let numericValue = e.target.value;
                                  if (numericValue === "") {
                                    numericValue = "0";
                                  }
                                  formik.setFieldValue(
                                    "price_grosir",
                                    formik.values.price_grosir.map(
                                      (g1, idx) => {
                                        return index === idx
                                          ? {
                                              ...g,
                                              price_grosirs: parseInt(
                                                numericValue.replace(
                                                  /[^0-9]/g,
                                                  ""
                                                ),
                                                10
                                              ),
                                            }
                                          : g1;
                                      }
                                    )
                                  );
                                }}
                              />
                              <button
                                type="button"
                                className="ml-[10px]"
                                onClick={() => {
                                  formik.setFieldValue("price_grosir", [
                                    ...formik.values.price_grosir.slice(
                                      0,
                                      index
                                    ),
                                    ...formik.values.price_grosir.slice(
                                      index + 1
                                    ),
                                  ]);
                                }}
                              >
                                <DeleteIcon sx={{ color: "black" }} />
                              </button>
                            </div>
                          </div>
                        );
                      })
                    : null}
                </>
              )}

              {isWarehouse ? null : (
                <p
                  className="text-blue-500 hover:underline hover:text-blue-500 cursor-pointer mt-4 text-end"
                  onClick={handleAddPlatform}
                >
                  +Tambah Platform lain
                </p>
              )}
            </>
          ) : (
            <>
              <div className="flex flex-wrap gap-2 mt-4">
                <p>Stock saat ini :</p>
                <p className="font-bold"></p>
              </div>
              <div className="flex flex-wrap gap-2 mt-4 items-center gap-3">
                <p>Tambah Stock</p>
                <div className="flex flex-row">
                  <input
                    type="text"
                    className="border rounded-lg w-[69px] p-3"
                    disabled
                  />
                </div>
              </div>
              <div className="flex flex-wrap gap-2 mt-4 mb-4">
                <p>Stock Akhir :</p>
              </div>
              <FormControl fullWidth>
                <Select
                  disabled
                  onChange={(e) => setQtyType(e.target.value)}
                  value={qtyType}
                >
                  <MenuItem
                    value={""}
                    sx={{ display: "none" }}
                    disabled
                  ></MenuItem>
                </Select>
              </FormControl>

              <p className="text-blue-500 hover:underline hover:text-blue-500 cursor-pointer mt-4 text-end">
                +Tambah Batch lain
              </p>

              <p className="font-bold text-[20px] mt-4">Update Stock</p>
              <hr className="border-2 border-black mt-2" />
              <TextField
                id="outlined-basic"
                fullWidth
                label="HNA"
                disabled
                autoComplete="off"
                variant="outlined"
                className="mt-3"
              />
              <TextField
                id="outlined-basic"
                fullWidth
                disabled
                sx={{ marginTop: 3 }}
                label="Discount"
                variant="outlined"
              />
              <div className="flex items-center mt-3">
                <div>
                  <p className="font-bold">PPN 11%</p>
                  <Switch disabled />
                </div>
                <TextField
                  id="outlined-basic"
                  sx={{ width: "20%", marginLeft: 2 }}
                  label="Harga Akhir HNA"
                  autoComplete="off"
                  variant="outlined"
                  className="mt-3"
                  disabled
                />
              </div>

              {isWarehouse ? null : (
                <>
                  {" "}
                  <TextField
                    id="outlined-basic"
                    label="Harga Jual Primer"
                    disabled
                    autoComplete="off"
                    variant="outlined"
                    className="mt-3"
                    placeholder="Rp."
                  />
                  <TextField
                    id="outlined-basic"
                    label="Harga Jual Sekunder"
                    disabled
                    autoComplete="off"
                    variant="outlined"
                    className="mt-3"
                    placeholder="Rp."
                  />
                  <TextField
                    id="outlined-basic"
                    label="Harga Jual Tersier"
                    disabled
                    autoComplete="off"
                    variant="outlined"
                    className="mt-3"
                    placeholder="Rp."
                  />
                  <p className="text-blue-500 hover:underline hover:text-blue-500 cursor-pointer mt-4 text-right w-full">
                    +Tambah harga grosir baru
                  </p>
                  <p className="text-blue-500 hover:underline hover:text-blue-500 cursor-pointer mt-4 text-end">
                    +Tambah Platform lain
                  </p>
                </>
              )}
            </>
          )}
          {isWarehouse ? null : (
            <>
              {" "}
              {platforms &&
              platforms !== undefined &&
              formik.values.platform_price?.length > 0 &&
              formik.values.platform_price !== null ? (
                <>
                  {formik.values.platform_price.map((plat, index) => {
                    return (
                      <div
                        className="flex justify-center items-center mt-[20px]"
                        key={plat.platform_id}
                      >
                        <FormControl fullWidth sx={{ marginRight: 1 }}>
                          <InputLabel id="demo-simple-select-label">
                            Kategori
                          </InputLabel>
                          <Select
                            id="add-category"
                            label="Kategori"
                            value={plat.platform_id}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "platform_price",
                                formik.values.platform_price.map((p1) => {
                                  return p1.platform_id === plat.platform_id
                                    ? {
                                        ...plat,
                                        platform_id: e.target.value,
                                      }
                                    : p1;
                                })
                              );
                            }}
                          >
                            {platforms.map((p) => {
                              return (
                                <MenuItem
                                  key={p.id}
                                  value={p.id}
                                  disabled={
                                    formik.values.platform_price.find(
                                      (f) => f.id === p.id
                                    )
                                      ? true
                                      : false
                                  }
                                  sx={{
                                    display: formik.values.platform_price.find(
                                      (f) => f.id === p.id
                                    )
                                      ? "none"
                                      : null,
                                  }}
                                >
                                  {p.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        <TextField
                          id="outlined-basic"
                          fullWidth
                          label="Harga"
                          autoComplete="off"
                          variant="outlined"
                          value={plat.price}
                          onChange={(e) => {
                            let numericValue = e.target.value;
                            if (numericValue === "") {
                              numericValue = "0";
                            }
                            formik.setFieldValue(
                              "platform_price",
                              formik.values.platform_price.map((p1) => {
                                return p1.platform_id === plat.platform_id
                                  ? {
                                      ...plat,
                                      price: parseInt(
                                        numericValue.replace(/[^0-9]/g, ""),
                                        10
                                      ),
                                    }
                                  : p1;
                              })
                            );
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                Rp.
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  edge="end"
                                  onClick={() => {
                                    if (
                                      formik.values.platform_price.length === 1
                                    )
                                      formik.setFieldValue(
                                        "platform_price",
                                        []
                                      );
                                    else
                                      formik.setFieldValue("platform_price", [
                                        ...formik.values.platform_price.slice(
                                          0,
                                          index
                                        ),
                                        ...formik.values.platform_price.slice(
                                          index + 1
                                        ),
                                      ]);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    );
                  })}
                </>
              ) : null}
            </>
          )}
        </div>
        <div className="grid grid-cols-2 mt-10 gap-12">
          <button
            className="bg-red-700 text-white font-semibold p-3 rounded-lg"
            onClick={() => navigate("/inventory/produk-aktif")}
          >
            Batal
          </button>
          <button
            className="bg-orange-400 text-white font-semibold p-3 rounded-lg disabled:bg-gray-500"
            // disabled={
            //   formik.values.batch
            //     .map((data) => data.number_batch)
            //     .includes("") ||
            //   formik.values.batch
            //     .map((data) => data.expired_date)
            //     .includes("") ||
            //   formik.values.batch.reduce((acc, num) => {
            //     return (
            //       acc +
            //       num.quantity / (qty.totalBox * qty.totalStrip * qty.totalUnit)
            //     );
            //   }, 0) !== count ||
            //   formik.values.batch.reduce((acc, num) => {
            //     return (
            //       acc +
            //       num.quantity / (qty.totalBox * qty.totalStrip * qty.totalUnit)
            //     );
            //   }, 0) === 0
            // }
            disabled={isValid || value === null}
            type="submit"
          >
            Simpan
          </button>
        </div>
      </div>
      <Snackbar
        open={Boolean(errorMessage)}
        onClose={() => setErrorMessage("")}
        message={errorMessage}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setErrorMessage("")}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </form>
  );
};

export default UpdateStock;
