import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import {
  Button,
  Divider,
  CircularProgress,
  ListItemButton,
  Collapse,
  TextField,
} from "@mui/material";
import { KeyboardArrowDown as KeyboardArrowDownIcon } from "@mui/icons-material";
import { BiExport, BiImport } from "react-icons/bi";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

import BillingNavigation from "../billing/BillingNavigation";
import { getNewBilling } from "../../../services/billingAPI";
import logoOlin from "../../../assets/icons/logoOlin.png";
import BillingBreadcrumb from "../billing/BillingBreadcrumb";
import ExportBillingModal from "./ExportBillingModal";
import ImportBillingModal from "./ImportBillingModal";
import { yearFormatBilling } from "../../constants/constant";

const BILLING_STATUS = 4;

const yearNow = moment();

const DraftBillingManual = ({ isWarehouse }) => {
  const navigate = useNavigate();

  const [openExportModal, setOpenExportModal] = useState(false);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [year, setYear] = useState(yearNow);
  const [dataBuffer, setDataBuffer] = useState([]);

  const {
    mutate: mutateUnpaidBilling,
    data: unpaidBillingData,
    isLoading: isUnpaidBillingDataLoading,
    error: isUnpaidBillingError,
  } = useMutation({
    mutationFn: getNewBilling,
    onError: (err) => setErrorMessage(err.id),
    onSuccess: (data) => {
      setDataBuffer(
        data && data !== undefined
          ? [
              ...new Set(data.map((data) => data.overdue_date.substr(0, 7))),
            ].map((item) => ({
              date: item,
              isOpen: false,
              buffer: data.map((item) => ({
                ...item,
                month: moment(item.overdue_date).format("MMMM"),
              })),
            }))
          : []
      );
    },
  });

  useEffect(() => {
    mutateUnpaidBilling({
      year: yearFormatBilling(year),
      status: BILLING_STATUS,
      warehouse: isWarehouse,
    });
  }, [mutateUnpaidBilling, year]);

  return (
    <div className="container mx-auto w-full mt-16 lg:mt-0">
      <div className="flex justify-between items-center">
        {/* <BillingBreadcrumb isWarehouse={isWarehouse} /> */}
        <div className="w-full flex justify-end gap-2">
          <Button
            sx={{ paddingX: 2 }}
            variant="outlined"
            onClick={() => setOpenImportModal(true)}
          >
            Import
            <BiImport className="ml-2" />
          </Button>
          <Button
            sx={{ paddingX: 2 }}
            variant="outlined"
            onClick={() => setOpenExportModal(true)}
          >
            Export
            <BiExport className="ml-2" />
          </Button>
          <Button
            sx={{ paddingX: 2 }}
            variant="contained"
            onClick={() =>
              navigate(
                `${isWarehouse ? "/central-warehouse" : ""}/manual-billing/list`
              )
            }
          >
            Create Billing
          </Button>
        </div>
      </div>
      <BillingNavigation />

      <div
        className="offcanvas offcanvas-bottom fixed bottom-0 flex flex-col max-w-full bg-white invisible bg-clip-padding shadow-sm outline-none transition duration-300 ease-in-out text-gray-700 left-0 right-0 border-none h-1/3 max-h-full"
        tabIndex="-1"
        id="offcanvasBottom"
        aria-labelledby="offcanvasBottomLabel"
      >
        <div className="offcanvas-header flex items-center justify-between p-4">
          <h5
            className="offcanvas-title mb-0 leading-normal font-semibold"
            id="offcanvasBottomLabel"
          >
            Offcanvas bottom
          </h5>
          <button
            type="button"
            className="btn-close box-content w-4 h-4 p-2 -my-5 -mr-2 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body flex-grow p-4 overflow-y-auto small">
          ...
        </div>
      </div>
      <div className="flex items-center">
        {unpaidBillingData && unpaidBillingData !== undefined ? (
          <p>
            Total Draft {unpaidBillingData.length},
            <span className="text-red-500"> Draft Bill</span>{" "}
            <span className="text-blue-500">
              Rp.
              {(unpaidBillingData && unpaidBillingData !== undefined
                ? unpaidBillingData.reduce(
                    (acc, num) => acc + num.total_amount,
                    0
                  )
                : 0
              ).toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
              })}
            </span>
          </p>
        ) : null}
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DesktopDatePicker
            fullWidth
            onChange={(newDate) => setYear(newDate)}
            value={year}
            renderInput={(params) => {
              return (
                <TextField {...params} sx={{ marginY: 3, marginLeft: 2 }} />
              );
            }}
            label="Select Year"
            views={["year"]}
          />
        </LocalizationProvider>
      </div>
      <div className="mb-8">
        {unpaidBillingData && unpaidBillingData !== undefined ? (
          <div>
            {dataBuffer.map((data, index) => (
              <div
                className="w-full border border-black my-3 rounded-md"
                key={index}
              >
                <ListItemButton
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  onClick={() => {
                    setDataBuffer((prevValue) =>
                      prevValue.map((item, idx) => {
                        return index === idx
                          ? {
                              ...item,
                              isOpen: !item.isOpen,
                            }
                          : item;
                      })
                    );
                  }}
                >
                  <div className="flex font-medium">
                    <p className="mr-2">
                      {moment(data.date).format("MMMM YYYY")},
                    </p>
                    <p>
                      {data.buffer && data.buffer !== undefined
                        ? data.buffer
                            .filter(
                              (item) =>
                                item.month === moment(data.date).format("MMMM")
                            )
                            .reduce((acc, num) => acc + num.total_amount, 0)
                            .toLocaleString("id-ID", {
                              style: "currency",
                              currency: "IDR",
                            })
                        : 0}
                    </p>
                  </div>
                  <KeyboardArrowDownIcon />
                </ListItemButton>
                {data.buffer && data.buffer !== undefined ? (
                  <Collapse
                    in={data.isOpen}
                    unmountOnExit
                    sx={{ fontWeight: "bold", padding: 2 }}
                  >
                    {data.buffer
                      .filter(
                        (item) =>
                          item.month === moment(data.date).format("MMMM")
                      )
                      .map((data, idx) => (
                        <div
                          className="mt-3 flex items-center shadow-md rounded-xl p-3 border-2 justify-between w-full"
                          key={idx}
                        >
                          <div className="flex items-center w-full">
                            <div className="w-full">
                              <div className="flex items-center py-2">
                                <div className="w-full flex justify-end">
                                  <div className="w-1/4 bg-blue-500 rounded-md text-white text-center font-bold py-2">
                                    DRAFT
                                  </div>
                                </div>
                              </div>
                              <Divider sx={{ marginY: 2, borderWidth: 1 }} />
                              <div className="flex my-2">
                                <img
                                  src={logoOlin}
                                  alt="foto_product"
                                  className="w-30 rounded-lg mx-2 p-2"
                                />
                                <div className="flex justify-between w-full">
                                  <div className="flex flex-col text-sm lg:text-base gap-1 text-clip overflow-hidden w-full">
                                    <p className="text-blue-500">
                                      {data.invoice_number}
                                    </p>
                                    <p>{data.po_number}</p>
                                    <p className="font-bold">
                                      Rp.
                                      {data.total_amount.toLocaleString(
                                        "id-ID",
                                        {
                                          style: "currency",
                                          currency: "IDR",
                                        }
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <Divider sx={{ marginY: 2, borderWidth: 1 }} />
                              <div className="flex w-full items-center justify-between">
                                <div className="basis-1/2 md:basis-1/4 float-left">
                                  <button
                                    onClick={() =>
                                      navigate(
                                        `${
                                          isWarehouse
                                            ? "/central-warehouse"
                                            : ""
                                        }/manual-billing/create-billing-draft/${
                                          data.po_id
                                        }`
                                      )
                                    }
                                    className="w-full bg-gray-700 text-white cursor-pointer hover:bg-black rounded-md text-center p-2"
                                  >
                                    Billing Detail
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </Collapse>
                ) : null}
              </div>
            ))}
          </div>
        ) : isUnpaidBillingDataLoading ? (
          <CircularProgress />
        ) : isUnpaidBillingError ? (
          <p className="text-red-500 py-4 text-center">
            {isUnpaidBillingError.message.id}
          </p>
        ) : (
          <div>
            <p>Tidak ditemukan!</p>
          </div>
        )}
      </div>
      <p className="text-red-500 text-center">{errorMessage}</p>

      <ExportBillingModal
        openModal={openExportModal}
        setOpenModal={setOpenExportModal}
      />

      <ImportBillingModal
        openModal={openImportModal}
        setOpenModal={setOpenImportModal}
      />
    </div>
  );
};

export default DraftBillingManual;
